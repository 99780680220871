import * as lodash from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import {
    ChangeableBudgetItemStatus,
    BudgetItemStatus,
    UpdateActivityBudgetForm,
    UpdateBudgetItemForm,
    ChangeBudgetItemStatusForm,
    Funds,
    Month,
} from '@mrm/budget';

import { StoreState } from '@store';
import { getLoginUser } from '@store/user';

import { ActivityBudgetApi, BudgetItemApi } from '@api';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { getBudgetTableState } from '../selectors';

export const initCorrections = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.initCorrections,
    async (_, dispatch, getState) => {
        const state = getState();
        const { data, dataUpdated } = getBudgetTableState(state);
        const loginnedUserId = getLoginUser(state).attributes.id;

        const activityBudgetParams: UpdateActivityBudgetForm[] = Object.values(dataUpdated.activityBudgets.byId).map(
            (ab) => ({
                id: ab.id,
                name: ab.name,
                expertId: loginnedUserId,
            }),
        );

        const budgetItemStatusParams: ChangeBudgetItemStatusForm[] = [];
        const budgetItemParams: UpdateBudgetItemForm[] = [];
        Object.values(dataUpdated.budgetItems.byId).forEach((bi) => {
            const origBi = data.budgetItems.byId[bi.id];

            const result: UpdateBudgetItemForm = {
                id: bi.id,
            };

            if (!lodash.isEqual(origBi.dictionary, bi.dictionary)) {
                result.dictionary = lodash.mapValues(bi.dictionary, (dictionary) => dictionary?.id || null);
            }
            if (origBi.realizationStart !== bi.realizationStart) {
                result.realizationStart = bi.realizationStart;
            }
            if (origBi.realizationEnd !== bi.realizationEnd) {
                result.realizationEnd = bi.realizationEnd;
            }
            if (origBi.previousFunds !== bi.previousFunds) {
                result.previousFunds = bi.previousFunds;
            }
            if (origBi.totalApprovedPlan !== bi.totalApprovedPlan) {
                result.totalApprovedPlan = bi.totalApprovedPlan;
            }
            if (origBi.sapNumber !== bi.sapNumber) {
                result.sapNumber = bi.sapNumber;
            }
            if (origBi.comment !== bi.comment) {
                result.comment = bi.comment;
            }

            const plannedFunds = makeCorrectionFunds(origBi.plannedFunds, bi.plannedFunds);
            const factFunds = makeCorrectionFunds(origBi.factFunds, bi.factFunds);
            const reservedFunds = makeCorrectionFunds(origBi.reservedFunds, bi.reservedFunds);

            if (plannedFunds) {
                result.plannedFunds = plannedFunds;
            }
            if (factFunds) {
                result.factFunds = factFunds;
            }
            if (reservedFunds) {
                result.reservedFunds = reservedFunds;
            }

            budgetItemParams.push(result);

            if (origBi.status !== bi.status) {
                const statusChangeParams: ChangeBudgetItemStatusForm = {
                    id: bi.id,
                    status: bi.status as ChangeableBudgetItemStatus,
                };

                if (bi.status === BudgetItemStatus.Rejected) {
                    statusChangeParams.comment = bi.expertComment;
                }

                budgetItemStatusParams.push(statusChangeParams);
            }
        });

        console.time('corrections');
        const updatedActivityBudgets = activityBudgetParams?.length
            ? await ActivityBudgetApi.groupUpdateActivityBudget({
                  group: activityBudgetParams,
              })
            : [];
        const fieldUpdatedBudgetItems = budgetItemParams.length
            ? await BudgetItemApi.groupUpdateBudgetItem({ groups: budgetItemParams })
            : [];
        const statusUpdatedBudgetItems = budgetItemStatusParams.length
            ? await BudgetItemApi.groupChangeBudgetItemStatus({ groups: budgetItemStatusParams })
            : [];
        const updatedBudgetItems = lodash.uniqBy(
            [...statusUpdatedBudgetItems, ...fieldUpdatedBudgetItems],
            (bi) => bi.id,
        );
        // for (let ab of activityBudgetParams) {
        //     await ActivityBudgetApi.updateActivityBudget(ab);
        // }
        // for (let bi of budgetItemParams) {
        //     await BudgetItemApi.updateBudgetItem(bi);
        // }
        // for (let status of budgetItemStatusParams) {
        //     await BudgetItemApi.changeBudgetItemStatus(status);
        // }
        console.timeEnd('corrections');

        dispatch(
            actions.syncDataAfterCorrection({
                activityBudgets: updatedActivityBudgets,
                budgetItems: updatedBudgetItems,
            }),
        );
    },
);

function makeCorrectionFunds(origFunds: Funds, funds: Funds): Partial<Funds> {
    const changedKeys = Object.keys(origFunds).filter((month: Month) => origFunds[month] !== funds[month]) as Month[];

    return changedKeys.length
        ? changedKeys.reduce((acc, month) => {
              acc[month] = funds[month];

              return acc;
          }, {} as Partial<Funds>)
        : null;
}
