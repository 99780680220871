import { combineReducers } from 'redux';

import { upd_BudgetPageState } from './types';

import { budgetTableReducer } from './budgetTable';
import { budgetTableFiltersReducer } from './budgetTableFilters';
import { transferPlanFundsFormReducer } from './transferPlanFundsForm';

export const upd_budgetPageReducer = combineReducers<upd_BudgetPageState>({
    budgetTable: budgetTableReducer,
    budgetTableFilters: budgetTableFiltersReducer,
    transferPlanFundsForm: transferPlanFundsFormReducer,
});
