import { Success } from 'typescript-fsa';

import { TransferPlanFundsFormState, CellsState as State, CellPosition } from '../types';

export class CellsReducer {
    public static makeInitialState(): State {
        return {
            from: [],
            to: [],
            hoveredCell: CellsReducer.makeClearCellPosition(),
        };
    }

    public static mergeAfterAsyncDone(
        state: TransferPlanFundsFormState,
        payload: Success<any, Partial<State>>,
    ): TransferPlanFundsFormState {
        return CellsReducer.merge(state, payload.result);
    }

    public static merge(state: TransferPlanFundsFormState, cells: Partial<State>): TransferPlanFundsFormState {
        return { ...state, cells: { ...state.cells, ...cells } };
    }

    private static makeClearCellPosition(): CellPosition {
        return { budgetItem: null, month: null };
    }
}
