import { actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('BUDGET_TABLE_ASYNC').async;

export const loadTableData = actionCreator<null, void, Error>('LOAD_TABLE_DATA');
export const loadBudgetItemsByActivityId = actionCreator<string, void, Error>('LOAD_BUDGET_ITEMS_BY_ACTIVITY_ID');

export const createActivityBudget = actionCreator<null, void, Error>('CREATE_ACTIVITY_BUDGET');
export const createBudgetItem = actionCreator<string, void, Error>('CREATE_BUDGET_ITEM');

export const applyFilters = actionCreator<null, void, Error>('APPLY_FILTERS');

export const initCorrections = actionCreator<null, void, Error>('INIT_CORRECTIONS');
export const updateAfterPlanCorrectons = actionCreator<string[], void, Error>('UPDATE_AFTER_PAL_CORRECTIONS');

export const sendBudgetItemToExpertApprovement = actionCreator<string, void, Error>(
    'SEND_BUDGET_ITEM_TO_EXPERT_APPROVEMENT',
);
export const deleteBudgetItem = actionCreator<string, void, Error>('DELETE_BUDGET_ITEM');
export const publishBudgteItem = actionCreator<string, void, Error>('PUBLISH_BUDGET_ITEM');

export const setActiveBudgetId = actionCreator<string, void, Error>('SET_ACTIVE_BUDGET_ID');
