import { BriefSchemeView } from 'sber-marketing-types/backend';

import { axios } from '../lib/axios';

const BASE_URL = '/api/organization/scheme';

export class OrganizationSchemeApi {
    public static async getBasicBriefScheme(organizationId: string): Promise<BriefSchemeView> {
        const { data } = await axios.get<{ scheme: BriefSchemeView }>(`${BASE_URL}/${organizationId}`);

        return data.scheme;
    }
}
