import { actionCreatorFactory } from 'typescript-fsa';

import {
    LoadFiltersPayload,
    LoadDictionaryFiltersPayload,
    SetDictionaryItemsPayload,
    SetDictionarySelectedItemsPayload,
} from '../types';

const actionCreator = actionCreatorFactory('BUDGET_TABLE_FILTERS_ASYNC').async;

export const loadFilters = actionCreator<LoadFiltersPayload, void, Error>('LOAD_FILTERS');
export const loadDictionaryFilters = actionCreator<LoadDictionaryFiltersPayload, void, Error>(
    'LOAD_DICTIONARY_FILTERS',
);
export const setDictionaryItems = actionCreator<SetDictionaryItemsPayload, void, Error>('SET_DICTIONARY_ITEMS');
export const setDictionarySelectedItems = actionCreator<SetDictionarySelectedItemsPayload, void, Error>(
    'SET_DICTIONARY_SELECTED_ITEMS',
);
export const resetFilters = actionCreator<null, void, Error>('RESET_FILTERS');
