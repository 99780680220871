import { BriefScheme } from 'sber-marketing-types/frontend';
import { CorrectionStatus, Budget } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';
import { UserResponseParams } from 'sber-marketing-types/frontend';

import { ColumnData } from '@store/budgetExecution/types';

import { BudgetCorrectionsFiltersAndExportState } from './filtersAndExport';

export interface BudgetCorrectionsPageState {
    filtersAndExport: BudgetCorrectionsFiltersAndExportState;
    restState: PageState;
}

export interface PageState {
    pageData?: PageData;
    unsavedChange?: UnsavedChange;
}

export interface PageData {
    budget?: Budget;
    dictionaries?: PlainDictionary[];
    users?: UserResponseParams[];
    budgetSchema?: BriefScheme;
    budgetColumns?: ColumnData[];
}

export interface UnsavedChange {
    correctionId?: string;
    newStatus?: CorrectionStatus;
    rejectComment?: string;
    sapExportMode?: CorrectionSapExportMode;
}

export const enum CorrectionSapExportMode {
    Upload = 'upload',
    NotUpload = 'notUpload',
    Uploaded = 'uploaded',
}
