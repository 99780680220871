import * as React from 'react';
import { get } from 'lodash';

import { SystemApi } from '@api';

interface Props {
    children: React.ReactNode;
}

interface LocalizationContextState {
    l10n: (key: string, ...args: any[]) => string;
}

const LocalizationContext = React.createContext<LocalizationContextState>({
    l10n: () => '',
});

function LocalizationProvider({ children }: Props): JSX.Element {
    const [locData, setLocData] = React.useState<object>({});

    const l10n = React.useCallback(
        (key: string, ...args: any[]): string => {
            const locEntry = get(locData, key) || '';

            return locEntry.replace(/{(\d)+}/g, function (_: any, argIndex: string) {
                return args[+argIndex] || '';
            });
        },
        [locData],
    );

    React.useEffect(() => {
        (async () => {
            setLocData(await SystemApi.getLocales());
        })();
    }, []);

    return <LocalizationContext.Provider value={{ l10n }}>{children}</LocalizationContext.Provider>;
}

export { LocalizationContext, LocalizationProvider };
