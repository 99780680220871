import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem, Filter } from '@mrm/budget';

import { TableItems } from '../types';

const actionCreator = actionCreatorFactory('BUDGET_TABLE_FILTERS');

export const applyLoadedFilters = actionCreator<BudgetItem[]>('LOAD_FILTERED_DATA');
export const setItems = actionCreator<TableItems<BudgetItem>>('SET_ITEMS');
export const setSelectedItems = actionCreator<Filter<BudgetItem>>('SET_SELECTED_ITEMS');
export const resetSelectedFilters = actionCreator('RESET_SELECTED_FILTERS');
