import * as lodash from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ActivityBudget, BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import { BudgetTableState as State, LoadBudgetItemsByActivityIdPayload, SyncDataAfterCorrectionPayload } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            loadingStatus: LoadingStatus.NOT_LOADED,
            activeBudgetId: null,
            data: {
                budgets: {
                    all: [],
                    byId: {},
                    byStatus: {},
                    byYear: {},
                },
                activityBudgets: {
                    all: [],
                    byId: {},
                },
                budgetItems: {
                    byId: {},
                    byActivityId: {},
                },
                dictionaries: {
                    all: [],
                    byId: {},
                    byType: {},
                },
                budgetScheme: null,
            },
            dataUpdated: {
                activityBudgets: {
                    byId: {},
                },
                budgetItems: {
                    byId: {},
                },
            },
            misc: {
                activityBudgetLineMenu: null,
                expandedActivityBudgetRowKeys: [],
                budgetItemLineMenu: null,
                budgetItemModal: null,
            },
        };
    }

    public static mergeState<L extends keyof State>(key: L) {
        return function reducer(state: State, data: State[L]): State {
            return { ...state, [key]: data };
        };
    }

    public static deepMergeState<L extends keyof Omit<State, 'activeBudgetId' | 'loadingStatus'>>(key: L) {
        return function reducer(state: State, data: State[L]): State {
            return { ...state, [key]: { ...state[key], ...data } };
        };
    }

    public static loadBudgetItemByActivityId(state: State, payload: LoadBudgetItemsByActivityIdPayload): State {
        const dataBudgetItems: State['data']['budgetItems'] = {
            ...state.data.budgetItems,
        };

        Object.keys(payload).forEach((activityId) => {
            const { loadingStatus, budgetItems } = payload[activityId];

            dataBudgetItems.byActivityId[activityId] = {
                loadingStatus,
                budgetItems,
            };
            budgetItems.forEach((bi) => (dataBudgetItems.byId[bi.id] = bi));
        });

        return {
            ...state,
            data: { ...state.data, budgetItems: dataBudgetItems },
        };
    }

    public static resetUpdatedData(state: State): State {
        return {
            ...state,
            dataUpdated: Reducer.makeInitialState().dataUpdated,
        };
    }

    public static resetFilteredData(state: State, filteredActivityBudgetIds: string[]): State {
        const budgetItemsByActivityId: Partial<State['data']['budgetItems']['byActivityId']> = {
            ...state.data.budgetItems.byActivityId,
        };
        filteredActivityBudgetIds.forEach(
            (activityBudgetId) => (budgetItemsByActivityId[activityBudgetId].loadingStatus = LoadingStatus.NOT_LOADED),
        );

        return {
            ...state,
            data: {
                ...state.data,
                budgetItems: {
                    ...state.data.budgetItems,
                    byActivityId: budgetItemsByActivityId,
                },
            },
        };
    }

    public static updateActivityBudget(state: State, ab: ActivityBudget): State {
        const origAb = state.data.activityBudgets.byId[ab.id];

        let updatedDataActivityBudgets: State['dataUpdated']['activityBudgets'];
        if (lodash.isEqual(ab, origAb)) {
            updatedDataActivityBudgets = { ...state.dataUpdated.activityBudgets };
            delete updatedDataActivityBudgets.byId[ab.id];
        } else {
            updatedDataActivityBudgets = {
                byId: {
                    ...state.dataUpdated.activityBudgets.byId,
                    [ab.id]: ab,
                },
            };
        }

        return {
            ...state,
            dataUpdated: { ...state.dataUpdated, activityBudgets: updatedDataActivityBudgets },
        };
    }

    public static updateBudgetItem(state: State, bi: BudgetItem): State {
        const origBi = state.data.budgetItems.byId[bi.id];

        let updatedDataBudgetItems: State['dataUpdated']['budgetItems'];
        if (lodash.isEqual(bi, origBi)) {
            updatedDataBudgetItems = { ...state.dataUpdated.budgetItems };
            delete updatedDataBudgetItems.byId[bi.id];
        } else {
            updatedDataBudgetItems = {
                byId: {
                    ...state.dataUpdated.budgetItems.byId,
                    [bi.id]: bi,
                },
            };
        }

        return {
            ...state,
            dataUpdated: { ...state.dataUpdated, budgetItems: updatedDataBudgetItems },
        };
    }

    public static syncDataAfterActivityBudgetAdd(state: State, ab: ActivityBudget): State {
        const data: State['data'] = {
            ...state.data,
            activityBudgets: {
                all: [ab, ...state.data.activityBudgets.all],
                byId: {
                    ...state.data.activityBudgets.byId,
                    [ab.id]: ab,
                },
            },
        };

        return {
            ...state,
            data,
        };
    }

    public static syncDataAfterBudgetItemAdd(state: State, bi: BudgetItem): State {
        if (!state.data.budgetItems.byActivityId[bi.activity.id]) {
            return state;
        }

        const data: State['data'] = {
            ...state.data,
            budgetItems: {
                byId: {
                    ...state.data.budgetItems.byId,
                    [bi.id]: bi,
                },
                byActivityId: {
                    ...state.data.budgetItems.byActivityId,
                    [bi.activity.id]: {
                        loadingStatus: state.data.budgetItems.byActivityId[bi.activity.id].loadingStatus,
                        budgetItems: [bi, ...state.data.budgetItems.byActivityId[bi.activity.id].budgetItems],
                    },
                },
            },
        };

        return {
            ...state,
            data,
        };
    }

    public static syncDataAfterCorrection(state: State, payload: SyncDataAfterCorrectionPayload) {
        const { activityBudgets, budgetItems } = payload;

        const data: State['data'] = {
            ...state.data,
            activityBudgets: {
                all: [...state.data.activityBudgets.all],
                byId: { ...state.data.activityBudgets.byId },
            },
            budgetItems: {
                byId: { ...state.data.budgetItems.byId },
                byActivityId: { ...state.data.budgetItems.byActivityId },
            },
        };

        const activityBudgetsMap = lodash.keyBy(activityBudgets, (ab) => ab.id);
        data.activityBudgets.all = data.activityBudgets.all.map((ab) => activityBudgetsMap[ab.id] || ab);

        const dataUpdated: State['dataUpdated'] = {
            activityBudgets: {
                byId: { ...state.dataUpdated.activityBudgets.byId },
            },
            budgetItems: {
                byId: { ...state.dataUpdated.budgetItems.byId },
            },
        };

        activityBudgets.forEach((ab) => {
            data.activityBudgets.byId[ab.id] = ab;

            delete dataUpdated.activityBudgets.byId[ab.id];
        });
        budgetItems.forEach((bi) => {
            data.budgetItems.byId[bi.id] = bi;

            data.budgetItems.byActivityId[bi.activity.id] = {
                loadingStatus: data.budgetItems.byActivityId[bi.activity.id].loadingStatus,
                budgetItems: data.budgetItems.byActivityId[bi.activity.id].budgetItems.map((bi2) =>
                    bi.id === bi2.id ? bi : bi2,
                ),
            };

            delete dataUpdated.budgetItems.byId[bi.id];
        });

        return {
            ...state,
            data,
            dataUpdated,
        };
    }
}

export const budgetTableReducer = reducerWithInitialState(Reducer.makeInitialState());

budgetTableReducer
    .case(actions.setLoadingStatus, Reducer.mergeState('loadingStatus'))
    .case(actions.setActiveBudgetId, Reducer.mergeState('activeBudgetId'))
    .case(actions.loadPageDataState, Reducer.mergeState('data'))
    .case(actions.loadPageDataUpdatedState, Reducer.mergeState('dataUpdated'))
    .case(actions.loadBudgetItemByActivityId, Reducer.loadBudgetItemByActivityId)
    .case(actions.resetUpdatedData, Reducer.resetUpdatedData)
    .case(actions.resetFilteredData, Reducer.resetFilteredData)
    .case(actions.setMiscState, Reducer.deepMergeState('misc'));

budgetTableReducer
    .case(actions.updateActivityBudget, Reducer.updateActivityBudget)
    .case(actions.updateBudgetItem, Reducer.updateBudgetItem);

budgetTableReducer
    .case(actions.syncDataAfterActivityBudgetAdd, Reducer.syncDataAfterActivityBudgetAdd)
    .case(actions.syncDataAfterBudgetItemAdd, Reducer.syncDataAfterBudgetItemAdd)
    .case(actions.syncDataAfterCorrection, Reducer.syncDataAfterCorrection);
