import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem } from '@mrm/budget';

import { CellsState, CellPosition, BudgetTransferDesciptor } from '../types';

const actionCreator = actionCreatorFactory('TRANSFER_PLAN_FUNDS_FORM_SYNC');

const startLoadingExperts = actionCreator('START_LOADING_EXPERTS');
const setHoveredCell = actionCreator<CellPosition>('SET_HOVERED_CELL');
const setDonorCell = actionCreator<CellPosition>('SET_DONOR_CELL');
const clearDonorCell = actionCreator('CLEAR_DONOR_CELL');
const setAcceptorCell = actionCreator<CellPosition>('SET_ACCEPTOR_CELL');
const setTransferAmount = actionCreator<BudgetTransferDesciptor>('SET_TRANSFER_AMOUNT');
const setParticipatorItemId = actionCreator<string>('SET_PARTICIPATOR_ITEM_ID');
const setParticipatorComment = actionCreator<string>('SET_PARTICIPATOR_COMMENT');
const selectExpert = actionCreator<number>('SELECT_EXPER');
const setRequestInProgress = actionCreator<boolean>('SET_REQUEST_IN_PROGRESS');
const setBudgetItemForSumEntering = actionCreator<BudgetItem>('SET_BUDGET_ITEM_FOR_SUM_ENTERING');
const setIsInternalTransferDirrectionToggleHovered = actionCreator<boolean>(
    'SET_IS_INTERNAL_TRANSFER_DIRECTION_TOGGLE_HOVERED',
);
const updateCells = actionCreator<Partial<CellsState>>('UPDATE_CELLS');

export {
    startLoadingExperts,
    setHoveredCell,
    setDonorCell,
    clearDonorCell,
    setAcceptorCell,
    setTransferAmount,
    setParticipatorItemId,
    setParticipatorComment,
    selectExpert,
    setRequestInProgress,
    setBudgetItemForSumEntering,
    setIsInternalTransferDirrectionToggleHovered,
    updateCells,
};
