import { Success } from 'typescript-fsa';

import {
    TransferPlanFundsFormState,
    ControlsState as State,
    ComponentState,
    InternalTransferDirection,
} from '../types';

export class ControlsReducer {
    public static makeInitialState(): State {
        return {
            componentState: ComponentState.Closed,
            internalTransferDirection: InternalTransferDirection.ManyToOne,
            isRequestInProgress: false,
            isTransferDirectionToggleHovered: false,
            isHoveredLineClickable: false,
            budgetItemForSumEntering: null,
        };
    }

    public static mergeAfterAsyncDone(
        state: TransferPlanFundsFormState,
        payload: Success<any, Partial<State>>,
    ): TransferPlanFundsFormState {
        return ControlsReducer.merge(state, payload.result);
    }

    public static merge(state: TransferPlanFundsFormState, controls: Partial<State>): TransferPlanFundsFormState {
        return { ...state, controls: { ...state.controls, ...controls } };
    }
}
