import { actionCreatorFactory } from 'typescript-fsa';
import { ActivityBudget, BudgetItem } from '@mrm/budget';

import { LoadingStatus } from '@store/commonTypes';

import {
    PageDataState,
    PageDataUpdatedState,
    LoadBudgetItemsByActivityIdPayload,
    SyncDataAfterCorrectionPayload,
    PageDataMiscState,
} from '../types';

const actionCreator = actionCreatorFactory('BUDGET_TABLE');

export const setLoadingStatus = actionCreator<LoadingStatus>('SET_LOADING_STATUS');
export const setActiveBudgetId = actionCreator<string>('SET_ACTIVE_BUDGET_ID');
export const loadPageDataState = actionCreator<PageDataState>('LOAD_PAGE_DATA_STATE');
export const loadPageDataUpdatedState = actionCreator<PageDataUpdatedState>('LOAD_PAGE_DATA_UPDATED_STATE');
export const loadBudgetItemByActivityId = actionCreator<LoadBudgetItemsByActivityIdPayload>(
    'LOAD_BUDGET_ITEMS_BY_ACTIVITY_ID',
);
export const resetUpdatedData = actionCreator('RESET_UPDATED_DATA');
export const resetFilteredData = actionCreator<string[]>('RESET_FILTERED_DATA');
export const setMiscState = actionCreator<Partial<PageDataMiscState>>('SET_MISC_STATE');

export const updateActivityBudget = actionCreator<ActivityBudget>('UPDATE_ACTIVITY_BUDGET');
export const updateBudgetItem = actionCreator<BudgetItem>('UPDATE_BUDGET_ITEM');

export const syncDataAfterActivityBudgetAdd = actionCreator<ActivityBudget>('SYNC_DATA_AFTER_ACTIVITY_BUDGET_ADD');
export const syncDataAfterBudgetItemAdd = actionCreator<BudgetItem>('SYNC_DATA_AFTER_BUDGET_ITEM_ADD');
export const syncDataAfterCorrection = actionCreator<SyncDataAfterCorrectionPayload>('SYNC_DATA_AFTER_CORRECTION');
