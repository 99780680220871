import { Month } from '@mrm/budget';

export class MonthUtils {
    public static Months: Month[] = [
        Month.Jan,
        Month.Feb,
        Month.Mar,
        Month.Apr,
        Month.May,
        Month.Jun,
        Month.Jul,
        Month.Aug,
        Month.Sept,
        Month.Oct,
        Month.Nov,
        Month.Dec,
    ];

    public static getQuarterMonths(q: number): Month[] {
        switch (q) {
            case 0:
                return [Month.Jan, Month.Feb, Month.Mar];
            case 1:
                return [Month.Apr, Month.May, Month.Jun];
            case 2:
                return [Month.Jul, Month.Aug, Month.Sept];
            case 3:
                return [Month.Oct, Month.Nov, Month.Dec];
            default:
                throw new Error(`Unknowm quarter index: ${q}`);
        }
    }
}
