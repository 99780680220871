import { v4 } from 'uuid';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { CreateActivityBudgetForm, CreateBudgetItemForm, BudgetItemStatus } from '@mrm/budget';

import { StoreState } from '@store';

import { ActivityBudgetApi, BudgetItemApi } from '@api';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { getBudgetTableState, planBudgetIsSelected } from '../selectors';

export const createActivityBudget = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.createActivityBudget,
    async (_, dispatch, getState) => {
        const budgetId = getBudgetTableState(getState()).activeBudgetId;

        const id = v4();
        const params: CreateActivityBudgetForm = {
            id,
            name: 'Новая activityBudget',
            budgetId,
        };

        console.time('createActivityBudget');
        await ActivityBudgetApi.createActivityBudget(params);
        const activityBudget = await ActivityBudgetApi.getActivityBudget(id);
        console.timeEnd('createActivityBudget');

        dispatch(actions.syncDataAfterActivityBudgetAdd(activityBudget));
    },
);

export const createBudgetItem = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.createBudgetItem,
    async (activityId, dispatch, getState) => {
        const state = getState();

        const shouldPublishRow = planBudgetIsSelected(state);
        const budgetId = getBudgetTableState(state).activeBudgetId;

        const id = v4();
        const params: CreateBudgetItemForm = {
            id,
            activityId,
            budgetId,
            responsibleIds: [],
            dictionaryIds: [],
        };

        console.time('createBudgetItem');
        await BudgetItemApi.createBudgetItem(params);

        if (shouldPublishRow) {
            await BudgetItemApi.changeBudgetItemStatus({
                id,
                status: BudgetItemStatus.Published,
            });
        }

        const budgetItem = await BudgetItemApi.getBudgetItem(id);

        console.timeEnd('createBudgetItem');

        dispatch(actions.syncDataAfterBudgetItemAdd(budgetItem));
    },
);
