import createCashedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { SelectItem } from 'sber-marketing-ui';
import { values, sum } from 'lodash';

import { StoreState } from '@store';

import { InternalTransferDirection, ComponentState, TransferPlanFundsFormState as State, CellPosition } from './types';
import { cellBelongsToLine, checkCellsEquality } from './misc';

export const getBudgetTransferMenuState = (state: StoreState): State => state.upd_budgetPage.transferPlanFundsForm;

export function isBudgetTransferMenuClosed(componentState: ComponentState) {
    return componentState === ComponentState.Closed;
}

export function isBudgetTransferMenuOpened(componentState: ComponentState) {
    return componentState !== ComponentState.Closed;
}

export const getExpertsAsSelectItems = createSelector(getBudgetTransferMenuState, (state): SelectItem[] =>
    state.experts.entities.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.secondName}`,
    })),
);

export const isLineDonor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string) => lineId,
    (state: State, lineId: string): boolean => state.cells.from.some((fromCell) => cellBelongsToLine(fromCell, lineId)),
)((state: StoreState, lineId: string) => lineId);

export const isCellDonor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, cell: CellPosition) => cell,
    (state: State, cell: CellPosition): boolean =>
        state.cells.from.some((fromCell) => checkCellsEquality(fromCell, cell)),
)((state: StoreState, cell: CellPosition) => `${cell.month}-${cell.budgetItem.id}`);

export const isLineAcceptor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string) => lineId,
    (state: State, lineId: string): boolean => state.cells.to.some((toCell) => cellBelongsToLine(toCell, lineId)),
)((state: StoreState, lineId: string) => lineId);

export const isCellAcceptor = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, cell: CellPosition) => cell,
    (state: State, cell: CellPosition): boolean => state.cells.to.some((toCell) => checkCellsEquality(toCell, cell)),
)((state: StoreState, cell: CellPosition) => `${cell.month}-${cell.budgetItem.id}`);

export const getTransferAmount = (state: State, from: CellPosition, to: CellPosition) => {
    return (
        state.transferDescriptors.find(
            (transferDescriptor) =>
                transferDescriptor.from?.budgetItem?.id === from.budgetItem.id &&
                transferDescriptor.from?.month === from.month &&
                transferDescriptor.to?.budgetItem?.id === to.budgetItem.id &&
                transferDescriptor.to?.month === to.month,
        )?.amount || 0
    );
};

export const getTotalTransferAmount = createSelector(getBudgetTransferMenuState, (state: State): number =>
    sum(state.transferDescriptors.map((descriptor) => descriptor.amount)),
);

export const getTotalTransferAmountForSource = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state, lineId) =>
        sum(
            state.transferDescriptors
                .filter(
                    (transferDescriptor) =>
                        transferDescriptor.from?.budgetItem?.id === lineId &&
                        transferDescriptor.from?.budgetItem?.id !== transferDescriptor.to?.budgetItem?.id,
                )
                .map((transferDesctriptor) => transferDesctriptor.amount),
        ) || 0,
)((state: StoreState, lineId: string): string => lineId);

export const getTotalTransferAmountForDest = createCashedSelector(
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state: State, lineId: string) =>
        sum(
            state.transferDescriptors
                .filter(
                    (transferDescriptor) =>
                        transferDescriptor.to?.budgetItem?.id === lineId &&
                        transferDescriptor.from?.budgetItem?.id !== transferDescriptor.to?.budgetItem?.id,
                )
                .map((transferDesctriptor) => transferDesctriptor.amount),
        ) || 0,
)((state: StoreState, lineId: string): string => lineId);

export const getTransferDesctiptors = createSelector(getBudgetTransferMenuState, (state: State) => {
    const {
        cells: { from, to },
        controls: { budgetItemForSumEntering, internalTransferDirection },
        transferDescriptors,
    } = state;

    let fromLine: string;
    let toLine: string;
    switch (internalTransferDirection) {
        case InternalTransferDirection.OneToMany:
            fromLine = from[0].budgetItem?.id;
            toLine = budgetItemForSumEntering.id;
            break;
        case InternalTransferDirection.ManyToOne:
            fromLine = budgetItemForSumEntering.id;
            toLine = to[0].budgetItem?.id;
            break;
        default:
            fromLine = null;
            toLine = null;
            break;
    }

    return transferDescriptors.filter(
        (transferAmount) =>
            transferAmount.from.budgetItem.id === fromLine && transferAmount.to.budgetItem.id === toLine,
    );
});

export const getFromLinesWithNegativePlanReserveDiff = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: State): CellPosition[] => {
        const {
            cells: { from },
        } = transitionData;

        return from.filter((fromCell) => {
            const fromBudgetItem = fromCell.budgetItem;
            const transferAmount = getTotalTransferAmountForSource(state, fromCell.budgetItem?.id);

            return (
                sum(values(fromBudgetItem?.plannedFunds || {})) -
                    sum(values(fromBudgetItem?.reservedFunds || {})) -
                    transferAmount <
                0
            );
        });
    },
);

export const canMoveAcceptorCellToDonor = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: State): boolean => {
        const internalTransferDirection = transitionData.controls.internalTransferDirection;
        const toCell = transitionData.cells.to[0];

        const checkCell = toCell && internalTransferDirection === InternalTransferDirection.ManyToOne;

        return checkCell ? !!toCell.budgetItem.plannedFunds[toCell.month] : true;
    },
);

export const getLinesMovebaleToDonor = createSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, transitionData: State): string[] => {
        const { to, from } = transitionData.cells;

        return to
            .filter((toCell) => {
                const toBudgetItemFunds = toCell.budgetItem.plannedFunds;

                if (from[0] && toCell.budgetItem.id === from[0].budgetItem.id) {
                    delete toBudgetItemFunds[from[0].month];
                }

                return !!values(toBudgetItemFunds).some((plan) => !!plan);
            })
            .map((toCell) => toCell.budgetItem.id);
    },
);

export const canMoveAcceptorLineToDonor = createCashedSelector(
    (state: StoreState) => state,
    getBudgetTransferMenuState,
    (state: StoreState, lineId: string): string => lineId,
    (state: StoreState, transitionData: State, lineId: string): boolean => {
        const internalTransferDirection = transitionData.controls.internalTransferDirection;
        const toCell = transitionData.cells.to.find((toCell) => toCell.budgetItem.id === lineId);

        const checkCell = toCell && internalTransferDirection === InternalTransferDirection.OneToMany;

        return checkCell ? !!values(toCell.budgetItem.plannedFunds).some((plan) => !!plan) : true;
    },
)((state: StoreState, lineId: string): string => lineId);
