import * as lodash from 'lodash';
import { createSelector } from 'reselect';
import { BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { getLoginUser, userHasUpdBudgetEditAccess } from '@store/user';
import { getBudgetTableFiltersState } from '@store/upd_budgetPage/budgetTableFilters';
import { isBudgetTransferMenuOpened, getBudgetTransferMenuState } from '@store/upd_budgetPage/transferPlanFundsForm';
import { BudgetTableState as State } from './types';

export const getBudgetTableState = (store: StoreState): State => store.upd_budgetPage.budgetTable;

export const getFilteredActivityBudgets = createSelector(
    (state: StoreState) => getBudgetTableState(state).data.activityBudgets.all,
    (state: StoreState) => getBudgetTableFiltersState(state).filteredData.activityBudgets,
    (activityBudgets, filteredActivirtyBudgetIds) =>
        lodash.orderBy(
            filteredActivirtyBudgetIds.size
                ? activityBudgets.filter((ab) => filteredActivirtyBudgetIds.has(ab.id))
                : activityBudgets,
            (ab) => ab.updateTime,
            'desc',
        ),
);

export const regularFieldsInteractionIsDisabled = createSelector(
    (state: StoreState) => isBudgetTransferMenuOpened(getBudgetTransferMenuState(state).controls.componentState),
    (state: StoreState) => !userHasUpdBudgetEditAccess(getLoginUser(state)),
    (budgetTransferMenuIsOpened, userWithoutBudgetAccess) => budgetTransferMenuIsOpened || userWithoutBudgetAccess,
);

export const planBudgetIsSelected = createSelector(
    (state: StoreState) => getBudgetTableState(state).data.budgets.byId,
    (state: StoreState) => getBudgetTableState(state).activeBudgetId,
    (budgetsById, activeBudgetId) => budgetsById[activeBudgetId]?.status === BudgetStatus.Plan,
);

export const executionBudgetIsSelected = createSelector(
    (state: StoreState) => getBudgetTableState(state).data.budgets.byId,
    (state: StoreState) => getBudgetTableState(state).activeBudgetId,
    (budgetsById, activeBudgetId) => budgetsById[activeBudgetId]?.status === BudgetStatus.Execution,
);
