import { get, includes, keys, isEqual, dropWhile, drop } from 'lodash';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';

import {
    AppliedFiltersNames,
    ColumnName,
    ColumnData,
    ColumnFiltersLoadingStatus,
    TableLine,
    UnsavedChange,
    Filters,
    ColumnsWithSameData,
    DateCellValue,
    NumericCellValue,
    LoadingStatus,
    ActivityDictionaryType,
    BudgetItemDictionaryType,
    FilterKey,
    ColumnNameToFilterMap,
} from './types';

export const buildFunctionForGetSelectedDictionaryItemByColumn =
    ({
        changes,
        line,
        dictionaries,
    }: {
        line: TableLine;
        changes: UnsavedChange[];
        dictionaries: { [dictionaryType: string]: PlainDictionary[] };
    }) =>
    (column: ColumnData): PlainDictionary => {
        const dictionaryType = get(column, 'metaData.dictionaryType') as DictionaryType;

        const unsavedChange = changes.find((item) => {
            const isBudgetItemMatched = item.budgetItemId === line.id;

            const columnsToChange: ColumnName[] = ColumnsWithSameData[item.columnName] || [item.columnName];
            const isColumnMatched = columnsToChange.some((columnName) => columnName === column.name);

            return isBudgetItemMatched && isColumnMatched;
        });

        const selectedDictionaryItemId = unsavedChange ? unsavedChange.value : line.fields[column.name];

        return selectedDictionaryItemId
            ? dictionaries[dictionaryType].find((item) => item.id == selectedDictionaryItemId)
            : null;
    };

interface GetSelectedColumnValueParams {
    change: UnsavedChange;
    line: TableLine;
    column: ColumnData;
    dictionaries: PlainDictionary[];
}

export const getSelectedColumnValue = ({
    change,
    line,
    column,
}: GetSelectedColumnValueParams): string | number | Date | NumericCellValue | DateCellValue => {
    if (change) {
        return change.value;
    }

    return line.fields[column.name];
};

interface UpdateAppliedFiltersNamesUseAllFiltersParams {
    filters: Filters;
    appliedFiltersNames: AppliedFiltersNames;
}

interface UpdateAppliedFiltersNamesUseTargetFilterParams {
    targetFilter: TargetFilter;
    appliedFiltersNames: AppliedFiltersNames;
}

interface TargetFilter {
    name: FilterKey;
    values: { [key: string]: boolean };
}

export const updateAppliedFiltersNamesUseAllFilters = ({
    filters,
    appliedFiltersNames,
}: UpdateAppliedFiltersNamesUseAllFiltersParams): AppliedFiltersNames => {
    return keys(filters).reduce((newAppliedFiltersNames, filterName: FilterKey) => {
        return updateAppliedFiltersNamesUseTargetFilter({
            targetFilter: {
                name: filterName,
                values: filters[filterName],
            },
            appliedFiltersNames: newAppliedFiltersNames,
        });
    }, appliedFiltersNames);
};

export const updateAppliedFiltersNamesUseTargetFilter = ({
    targetFilter,
    appliedFiltersNames,
}: UpdateAppliedFiltersNamesUseTargetFilterParams): AppliedFiltersNames => {
    const appliedFilterValues = getAppliedFilterValues(targetFilter.values);

    const hasTargetFilterValues = keys(appliedFilterValues).length;
    const isTargetFilterApplied = appliedFiltersNames.some((value) => value === targetFilter.name);

    if (hasTargetFilterValues && !isTargetFilterApplied) {
        return [...appliedFiltersNames, targetFilter.name];
    }

    if (!hasTargetFilterValues && isTargetFilterApplied) {
        return appliedFiltersNames.filter((filterColumnName) => filterColumnName !== targetFilter.name);
    }

    return appliedFiltersNames;
};

export const getAppliedFilterValues = (value: Record<string, boolean>): Record<string, boolean> => {
    return keys(value).reduce((prev, currentKey) => {
        return value[currentKey]
            ? {
                  ...prev,
                  [currentKey]: value[currentKey],
              }
            : prev;
    }, {});
};

export interface UpdateColumnFilterLoadingStatusParams {
    filtersGroup: {
        prev: Filters;
        current: Filters;
    };
    appliedFiltersNamesGroup: {
        prev: AppliedFiltersNames;
        current: AppliedFiltersNames;
    };
    columnFiltersLoadingStatus: ColumnFiltersLoadingStatus;
}

export const updateColumnFilterLoadingStatus = ({
    filtersGroup,
    appliedFiltersNamesGroup,
    columnFiltersLoadingStatus,
}: UpdateColumnFilterLoadingStatusParams): ColumnFiltersLoadingStatus => {
    const changedColumnFilterNames = keys(filtersGroup.current).reduce((changedFilterColumnNames, filterColumnName) => {
        const prevFilter = filtersGroup.prev[filterColumnName];
        const currentFilter = filtersGroup.current[filterColumnName];

        const haveChanges = !isEqual(currentFilter, prevFilter);

        return haveChanges ? [...changedFilterColumnNames, filterColumnName] : changedFilterColumnNames;
    }, []);

    const columnNamesForUpdatingLoadingStatus = drop(
        dropWhile(appliedFiltersNamesGroup.prev, (appliedFilterName) => {
            return !includes(changedColumnFilterNames, appliedFilterName);
        }),
    );

    const updatedFiltersLoadingStatus = keys(columnFiltersLoadingStatus).reduce(
        (newColumnFiltersLoadingStatus, columnName) => {
            if (includes(columnNamesForUpdatingLoadingStatus, columnName)) {
                return {
                    ...newColumnFiltersLoadingStatus,
                    [columnName]: LoadingStatus.NOT_LOADED,
                };
            }

            if (!includes(appliedFiltersNamesGroup.current, columnName)) {
                return {
                    ...newColumnFiltersLoadingStatus,
                    [columnName]: LoadingStatus.NOT_LOADED,
                };
            }

            return {
                ...newColumnFiltersLoadingStatus,
                [columnName]: columnFiltersLoadingStatus[columnName],
            };
        },
        {},
    );

    return updatedFiltersLoadingStatus;
};

const DictionaryTypeToColumnNameMap = {
    [ActivityDictionaryType.ActivityType]: ColumnName.ActivityType,
    [BudgetItemDictionaryType.LocationDriver]: ColumnName.LocationDriver,
};

export const getColumnNameFromDictionaryType = (
    dictionaryType: ActivityDictionaryType | BudgetItemDictionaryType,
): ColumnName => {
    return DictionaryTypeToColumnNameMap[dictionaryType] || dictionaryType;
};

export function convertColumnToFilterKey(column: string, params?: { addIdMarker?: boolean }) {
    const dictionaryCodeColumn = column.match(/dictionary-code_([a-z_]*)/)?.[1];
    const dictionaryColumn = column.match(/dictionary_([a-z_]*)/)?.[1];

    return dictionaryCodeColumn
        ? `dictionary.${dictionaryCodeColumn}.code`
        : dictionaryColumn
        ? `dictionary.${dictionaryColumn}${params?.addIdMarker ? '.id' : ''}`
        : ColumnNameToFilterMap[column];
}
