import * as lodash from 'lodash';
import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { Budget, ActivityBudget } from '@mrm/budget';
import { PlainDictionary } from '@mrm/dictionary';
import { BriefScheme } from 'sber-marketing-types/frontend';
import { BudgetItemListQueryParams, BudgetStatus } from '@mrm/budget';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { getLoginUser } from '@store/user';
import {
    getBudgetTableFiltersState,
    applyLoadedFilters,
    filtersArePending,
} from '@store/upd_budgetPage/budgetTableFilters';

import { ActivityBudgetApi, BudgetApi, BudgetItemApi, DictionaryApi } from '@api';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { PageDataState, PageDataUpdatedState, LoadBudgetItemsByActivityIdPayload } from '../types';

import { getBudgetTableState } from '../selectors';

export const loadTableData = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.loadTableData,
    async (_, dispatch, getState) => {
        const state = getState();

        dispatch(actions.setLoadingStatus(LoadingStatus.LOADING));

        const { activeBudgetId } = getBudgetTableState(state);

        const { userOrganizationBudgetScheme } = getLoginUser(state);

        try {
            console.time('thunk: loadData (fetching)');
            const budgets = await BudgetApi.getBudgetList();

            let activeBudget = activeBudgetId
                ? budgets.find((budget) => budget.id === activeBudgetId)
                : lodash.maxBy(
                      budgets.filter((budget) => budget.status === BudgetStatus.Execution),
                      (budget) => budget.year,
                  );
            const budgetId = activeBudget.id;

            const [activityBudgets, dictionaries] = await Promise.all([
                ActivityBudgetApi.getActivityBudgetList({ budgetId }),
                DictionaryApi.getDictionaryList({
                    organizationId: activeBudget.dictionaryOrganizationId,
                }),
            ]);

            console.timeEnd('thunk: loadData (fetching)');
            const pageData: PageDataState = initPageData({
                budgets,
                activityBudgets,
                dictionaries,
                budgetScheme: userOrganizationBudgetScheme,
            });
            const pageDataUpdatedState: PageDataUpdatedState = {
                activityBudgets: {
                    byId: {},
                },
                budgetItems: {
                    byId: {},
                },
            };

            dispatch(actions.loadPageDataState(pageData));
            dispatch(actions.loadPageDataUpdatedState(pageDataUpdatedState));
            dispatch(actions.setActiveBudgetId(budgetId));
            dispatch(actions.setLoadingStatus(LoadingStatus.LOADED));
        } catch (e) {
            dispatch(actions.setLoadingStatus(LoadingStatus.ERROR));

            throw new Error(e);
        }
    },
);

export const loadBudgetItemsByActivityId = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.loadBudgetItemsByActivityId,
    async (activityId, dispatch, getState) => {
        const state = getState();
        const { activeBudgetId, data } = getBudgetTableState(state);
        const budgetItemsByActivityIdState = data.budgetItems.byActivityId[activityId];
        const initLoading =
            !budgetItemsByActivityIdState ||
            [LoadingStatus.NOT_LOADED, LoadingStatus.ERROR].includes(budgetItemsByActivityIdState.loadingStatus);

        if (initLoading) {
            try {
                dispatch(
                    actions.loadBudgetItemByActivityId({
                        [activityId]: {
                            loadingStatus: LoadingStatus.LOADING,
                            budgetItems: [],
                        },
                    }),
                );

                const budgetItems = await BudgetItemApi.getBudgetItemList({
                    budgetId: activeBudgetId,
                    activityIds: [activityId],
                });

                dispatch(
                    actions.loadBudgetItemByActivityId({
                        [activityId]: {
                            loadingStatus: LoadingStatus.LOADED,
                            budgetItems,
                        },
                    }),
                );
            } catch (e) {
                dispatch(
                    actions.loadBudgetItemByActivityId({
                        [activityId]: {
                            loadingStatus: LoadingStatus.ERROR,
                            budgetItems: [],
                        },
                    }),
                );
            }
        }
    },
);

export const applyFilters = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.applyFilters,
    async (_, dispatch, getState) => {
        const state = getState();
        const { activeBudgetId } = getBudgetTableState(state);
        const { selectedItems } = getBudgetTableFiltersState(state);

        const params: BudgetItemListQueryParams = {
            budgetId: activeBudgetId,
            filter: selectedItems,
        };

        const filteredBudgetItems = filtersArePending(state) ? await BudgetItemApi.getBudgetItemList(params) : [];

        const budgetItemsByActivityId: LoadBudgetItemsByActivityIdPayload = {};
        filteredBudgetItems.forEach((bi) => {
            if (!budgetItemsByActivityId[bi.activity.id]) {
                budgetItemsByActivityId[bi.activity.id] = {
                    loadingStatus: LoadingStatus.LOADED,
                    budgetItems: [],
                };
            }

            budgetItemsByActivityId[bi.activity.id].budgetItems.push(bi);
        });

        dispatch(actions.loadBudgetItemByActivityId(budgetItemsByActivityId));
        dispatch(applyLoadedFilters(filteredBudgetItems));
    },
);

function initPageData(params: {
    budgets: Budget[];
    activityBudgets: ActivityBudget[];
    dictionaries: PlainDictionary[];
    budgetScheme: BriefScheme;
}): PageDataState {
    console.time('thunk: loadData (object init)');

    const budgets: PageDataState['budgets'] = {
        all: params.budgets,
        byId: {},
        byYear: {},
        byStatus: {},
    };
    params.budgets.forEach((b) => {
        budgets.byId[b.id] = b;

        if (!budgets.byYear[b.year]) {
            budgets.byYear[b.year] = [];
        }
        budgets.byYear[b.year].push(b);

        if (!budgets.byStatus[b.status]) {
            budgets.byStatus[b.status] = [];
        }
        budgets.byStatus[b.status].push(b);
    });

    const activityBudgets: PageDataState['activityBudgets'] = {
        all: params.activityBudgets,
        byId: {},
    };
    params.activityBudgets.forEach((ab) => {
        activityBudgets.byId[ab.id] = ab;
    });

    const budgetItems: PageDataState['budgetItems'] = {
        byId: {},
        byActivityId: {},
    };

    const dictionaries: PageDataState['dictionaries'] = {
        all: params.dictionaries,
        byId: {},
        byType: {},
    };
    params.dictionaries.forEach((d) => {
        dictionaries.byId[d.id] = d;

        if (!dictionaries.byType[d.type]) {
            dictionaries.byType[d.type] = [];
        }
        dictionaries.byType[d.type].push(d);
    });

    console.timeEnd('thunk: loadData (object init)');

    return {
        budgets,
        activityBudgets,
        budgetItems,
        dictionaries,
        budgetScheme: params.budgetScheme,
    };
}
