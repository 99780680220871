import * as lodash from 'lodash';
import { PlainDictionary, DictionaryType, DictionaryStatus } from '@mrm/dictionary';

import {
    REGIONALITY_CENTRAL_BANK_ID,
    REGIONALITY_TYPE_CENTRAL_BANK,
    REGIONALITY_BASED_DICTIONARIES,
    BLOCK_BASED_DICTIONARIES,
    GroupedDictionaries,
    DictionariesByType,
    DictionaryValue,
    isMainRegionalitySelected,
} from './common';

import { RegionalityUsecasesCache } from './RegionalityUsecasesCache';

export class DictionariesFilter {
    private dictionaries: GroupedDictionaries;
    private regionalityUsecasesCache: RegionalityUsecasesCache;
    public useMultireferenceLogic: boolean;

    public constructor(dictionaries: GroupedDictionaries) {
        this.dictionaries = dictionaries;
        this.regionalityUsecasesCache = new RegionalityUsecasesCache(dictionaries);
        this.setUseMultireferenceLogic();
    }

    public filterDictionariesByValue(value: DictionaryValue, dictionaryType?: DictionaryType): DictionariesByType {
        let dictionaries: PlainDictionary[] = [];

        if (this.useMultireferenceLogic) {
            if (dictionaryType) {
                switch (dictionaryType) {
                    case DictionaryType.Regionality:
                    case DictionaryType.Direction:
                    case DictionaryType.Tool:
                    case DictionaryType.Resource:
                    case DictionaryType.Item:
                        dictionaries = this.regionalityBasedDictionariesForValue(value);
                        break;
                    case DictionaryType.Block:
                    case DictionaryType.Product:
                    case DictionaryType.Segment:
                    case DictionaryType.Channel:
                        dictionaries = this.blockBasedDictionariesForValue(value);
                        break;
                    case DictionaryType.Division:
                        dictionaries = this.divisionDictionariesForValue(value);
                        break;
                    default:
                        dictionaries = [value[dictionaryType]];
                        break;
                }
            } else {
                const regionalityBasedDictionaries = this.regionalityBasedDictionariesForValue(value);
                const blockBasedDictionaries = this.blockBasedDictionariesForValue(value);
                const divisionDictionaries = this.divisionDictionariesForValue(value);
                const miscDictionaries = this.miscDictionaries(value);

                dictionaries = lodash.uniqBy(
                    lodash.compact([
                        ...regionalityBasedDictionaries,
                        ...blockBasedDictionaries,
                        ...divisionDictionaries,
                        ...miscDictionaries,
                    ]),
                    (dictionary) => dictionary.id,
                );
            }
        } else {
            dictionaries = dictionaryType
                ? this.dictionaries.byType[dictionaryType]
                : Object.values(this.dictionaries.byId);
        }

        return lodash.groupBy(
            lodash.uniqBy(
                lodash.compact(dictionaries).filter((dictionary) => dictionary.status === DictionaryStatus.ACTIVE),
                (dictionary) => dictionary.id,
            ),
            (dictionary) => dictionary.type,
        ) as DictionariesByType;
    }

    private setUseMultireferenceLogic() {
        this.useMultireferenceLogic =
            false &&
            !!(
                this.dictionaries.byType[DictionaryType.RegionalityType]?.filter(
                    (dictionary) => dictionary.status === DictionaryStatus.ACTIVE,
                )?.length &&
                this.dictionaries.byType[DictionaryType.RegionalityUsecase]?.filter(
                    (dictionary) => dictionary.status === DictionaryStatus.ACTIVE,
                )?.length
            );
    }

    private regionalityBasedDictionariesForValue(value: DictionaryValue): PlainDictionary[] {
        const usecasesByValue = this.regionalityUsecasesCache.filterUsecasesByValue(value);

        const result = usecasesByValue.flatMap((usecase) => [
            usecase[DictionaryType.Direction],
            usecase[DictionaryType.Tool],
            usecase[DictionaryType.Resource],
            usecase[DictionaryType.Item],
        ]);

        if (value[DictionaryType.Regionality]) {
            result.push(value[DictionaryType.Regionality]);
        } else if (this.dictionaries.byType[DictionaryType.Regionality].length === 1) {
            result.push(this.dictionaries.byType[DictionaryType.Regionality][0]);
        } else if (
            usecasesByValue.every(
                (usecase) => usecase[DictionaryType.RegionalityType].id === REGIONALITY_TYPE_CENTRAL_BANK,
            )
        ) {
            result.push(this.dictionaries.byId[REGIONALITY_CENTRAL_BANK_ID]);
        }

        return result;
    }

    private blockBasedDictionariesForValue(value: DictionaryValue): PlainDictionary[] {
        const { byId: dictionariesById } = this.dictionaries;

        const blockValue = value[DictionaryType.Block];
        if (blockValue) {
            return [blockValue, ...blockValue.childrenRefs.map((ref) => dictionariesById[ref.id])];
        } else {
            const blockAssociatedValues = lodash.compact(
                BLOCK_BASED_DICTIONARIES.filter((dictionaryType) => dictionaryType !== DictionaryType.Block).map(
                    (dictionaryType) => value[dictionaryType],
                ),
            );

            if (!blockAssociatedValues.length) {
                return [];
            }

            const blocksByAssociatedValues = lodash.intersection(
                ...blockAssociatedValues.map((valueDictionary) =>
                    valueDictionary.parentRefs.filter((ref) => dictionariesById[ref.id]).map((ref) => ref.id),
                ),
            );

            if (!blocksByAssociatedValues.length) {
                console.warn(
                    `You are not supposed to be here!\nMissing block for a given channel-segment-product combination`,
                );

                return [];
            }

            const availableAssociatedDictionariesByBlocks = lodash.uniq(
                lodash.flatten(
                    blocksByAssociatedValues.map((blockId) =>
                        dictionariesById[blockId].childrenRefs.map((ref) => ref.id),
                    ),
                ),
            );

            return [...blocksByAssociatedValues, ...availableAssociatedDictionariesByBlocks].map(
                (dictionaryId) => dictionariesById[dictionaryId],
            );
        }
    }

    private divisionDictionariesForValue(value: DictionaryValue): PlainDictionary[] {
        const { byId: dictionariesById, byType: dictionariesByType } = this.dictionaries;

        const mainRegionalityIsSelected = isMainRegionalitySelected(value);

        let result: PlainDictionary[];

        if (!mainRegionalityIsSelected || value[DictionaryType.Block]) {
            result = lodash
                .compact(
                    (mainRegionalityIsSelected
                        ? value[DictionaryType.Block]?.childrenRefs
                        : value[DictionaryType.Regionality]?.childrenRefs
                    )?.map((ref) => dictionariesById[ref.id]),
                )
                .filter((dictionary) => dictionary.type === DictionaryType.Division);
        } else {
            result = dictionariesByType[DictionaryType.Division];
        }

        return result || [];
    }

    // returns rest Dictionaries for current value
    private miscDictionaries(value: DictionaryValue): PlainDictionary[] {
        const dictionaryTypesToSkip = [
            ...REGIONALITY_BASED_DICTIONARIES,
            ...BLOCK_BASED_DICTIONARIES,
            DictionaryType.Division,
        ];

        return Object.keys(value)
            .filter(
                (dictionaryType: DictionaryType) =>
                    value[dictionaryType] && !dictionaryTypesToSkip.includes(dictionaryType),
            )
            .map((dictionaryType: DictionaryType) => value[dictionaryType]);
    }
}
