import { UserResponseParams, BriefScheme } from 'sber-marketing-types/frontend';
import { ActivityBudget, BudgetItem, Month, Budget } from '@mrm/budget';
import { PlainDictionary, DictionaryType } from '@mrm/dictionary';

import { FormField } from 'sber-marketing-ui';

export interface PageState {
    activityBudget?: ActivityBudget;
    budget?: Budget;
    budgetItems?: BudgetItem[];
    activityForm?: FormData;
    budgetItemForms?: FormData[];
    usedDictionaries?: GroupedDictionaries;
    availableDictionaries?: GroupedDictionaries;
    users?: UserResponseParams[];
    isNameInputFocused?: boolean;
    budgetScheme?: BriefScheme;
}

export interface FormData {
    id: string;
    tagsEditorId?: string;
    fields: FormField[];
    collapsed?: boolean;
    isNew?: boolean;
    activityNameIsFocused?: boolean;
    transferDestinationId?: string;
}

export interface GroupedDictionaries {
    byId: Record<string, PlainDictionary>;
    byType: Partial<Record<DictionaryType, PlainDictionary[]>>;
}

export const activityBudgetRequiredFields = ['name'];

// export const budgetItemRequiredFields = ['sapComment'];

export const MONTHS = [
    Month.Jan,
    Month.Feb,
    Month.Mar,
    Month.Apr,
    Month.May,
    Month.Jun,
    Month.Jul,
    Month.Aug,
    Month.Sept,
    Month.Oct,
    Month.Nov,
    Month.Dec,
];

export const IgnoreDictionariesChildren = [DictionaryType.Regionality];

export type { Budget };
