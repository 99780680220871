import { axios, AxiosResponse } from '../lib/axios';

import { OrganizationListQuery, OrganizationView as Organization, BriefSchemeView } from 'sber-marketing-types/backend';

const BASE_PATH = '/api/organization';

export class OrganizationApi {
    public static async getOrganization(params?: OrganizationListQuery): Promise<Organization[]> {
        const res: AxiosResponse<Organization[]> = await axios.get(`${BASE_PATH}/list`, { params });

        return res.data;
    }

    public static async getOrganizationBudgetSchema(id: string): Promise<BriefSchemeView> {
        const res: AxiosResponse<BriefSchemeView> = await axios.get(`${BASE_PATH}/${id}/budget-schema`);

        return res.data;
    }
}
