import { axios } from '../lib/axios';

import { CreateGeneralCommentParams } from 'sber-marketing-types/backend';

const BASE_URL = '/api/comment';

export class CommentApi {
    public static async createComment(params: CreateGeneralCommentParams): Promise<void> {
        await axios.post(BASE_URL, params);
    }
}
