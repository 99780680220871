import * as React from 'react';

import { LocalizationContext } from './LocalizationProvider';

const useL10n = () => React.useContext(LocalizationContext);

interface L10NProps {
    locKey: string;
    args?: any[];
    component?: keyof React.ReactHTML;
}

function L10N({ locKey, args = [], component = 'span' }: L10NProps): JSX.Element {
    const { l10n } = useL10n();

    return React.createElement(component, null, l10n(locKey, ...args));
}

export { useL10n, L10N };
