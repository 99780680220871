import * as moment from 'moment';

import { BudgetItemStatus } from '@mrm/budget';
import { ColumnData, ColumnName, TableLine, ChangeList, BudgetColumns } from '@store/budgetPlanning/types';
import { CellParams, CellBackgroundColor } from '../LayerManager';

import { getFieldValue } from './Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    columns: BudgetColumns;
    unsavedChanges: ChangeList;
    validationStatus: boolean;
}

export class DatepickerCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private columns: BudgetColumns;
    private unsavedChanges: ChangeList;
    private validationStatus: boolean;

    constructor(props: Props) {
        this.line = props.line;
        this.column = props.column;
        this.columns = props.columns;
        this.unsavedChanges = props.unsavedChanges;
        this.validationStatus = props.validationStatus;
    }

    public makeCellParams(): CellParams {
        let value: Date;
        let color: CellBackgroundColor;

        const unsavedValue = this.getUnsavedValue(this.column);
        const baseValue = this.getBaseValue(this.column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            value = baseValue;
        }

        const title = value ? moment(value).format('DD MMMM YYYY') : null;

        let minDate = this.column.metaData && (this.column.metaData.minDate as Date);
        let maxDate = this.column.metaData && (this.column.metaData.maxDate as Date);

        if (this.column.name == ColumnName.StartDate) {
            const endDate = this.getValueByColumnName(ColumnName.EndDate);

            maxDate = endDate || maxDate;
        }

        if (this.column.name == ColumnName.EndDate) {
            const startDate = this.getValueByColumnName(ColumnName.StartDate);

            minDate = startDate || minDate;
        }

        const lineIsDraft = this.line.status == BudgetItemStatus.Draft;
        const lineIsDisabled = !this.line.canEdit || this.line.status == BudgetItemStatus.Disabled;

        return {
            title,
            value,
            tooltip: title || '-',
            originalValue: baseValue,
            minDate: minDate || null,
            maxDate: maxDate || null,
            bgColor: !lineIsDraft && color,
            displayValidationError: this.cellIsInvalid(value),
            disabled: lineIsDisabled,
        };
    }

    private getUnsavedValue(column: ColumnData): Date {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = changes.find(
            (item) => item.budgetItemId == this.line.id && item.columnName == column.name,
        );

        return unsavedChange ? (unsavedChange.value as Date) : undefined;
    }

    private getBaseValue(column: ColumnData): Date {
        return getFieldValue(this.line.fields[column.name], column.valueType) as Date;
    }

    private getValueByColumnName(columnName: ColumnName): Date {
        const column = this.columns.byName[columnName];

        let value: Date;

        const unsavedValue = this.getUnsavedValue(column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
        } else {
            const baseValue = this.getBaseValue(column);

            value = baseValue;
        }

        return value;
    }

    private cellIsInvalid(value: Date) {
        return this.validationStatus && !value;
    }
}
