import * as lodash from 'lodash';

import {
    ActivityBudget,
    BudgetItem,
    UpdateActivityBudgetForm,
    UpdateBudgetItemForm,
    TransferPlanFundsForm,
    CorrectionType,
    Funds,
} from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';
import {
    ColumnData,
    ColumnName,
    UnsavedChange,
    ChangeList,
    ACTIVITY_FIELDS_COLUMN_NAMES,
    BUDGET_ITEM_FIELDS_COLUMN_NAMES,
    BUDGET_ITEM_DICTIONARY_COLUMN_NAMES,
    ColumnsNameWithCodes,
    RESERVED_COLUMN_NAMES,
    FACT_COLUMN_NAMES,
    PLANNED_COLUMN_NAMES,
    MONTH_BY_COLUMN_NAMES,
    getBudgetExecutionPageState,
    getUnsavedChanges,
    GroupedCorrections,
} from '@store/budgetExecution';
import {
    ComponentState,
    getBudgetTransferMenuState,
    isExternalTransferState,
    BudgetTransferDesciptor,
} from '@store/budgetExecution/budgetTransferMenu';
import { store } from '@store';
import { ActivityBudgetApi, BudgetItemApi } from '@api';

import { Utils } from '@common/Utils';

interface StoreProps {
    activityBudgets: ActivityBudget[];
    budgetItems: BudgetItem[];
    reserveCorrections: GroupedCorrections<CorrectionType.ReservedFunds>;
    unsavedChanges: ChangeList;
    lineIdsWithActualChanges: string[];
    componentState: ComponentState;
    participatorItemId: string;
    participatorComment: string;
    budgetColumns: Record<string, ColumnData>;
}

export class Saver {
    private static instance: Saver;

    public static getInstance(): Saver {
        if (!Saver.instance) {
            Saver.instance = new Saver();
        }
        return Saver.instance;
    }

    public async saveTable(approverId: number) {
        const { lineIdsWithActualChanges } = this.store;

        const changedActivities = lodash.uniqBy(
            lineIdsWithActualChanges.map((lineId) => this.getActivityByLineId(lineId)),
            (activity) => activity.id,
        );

        for (const { id } of changedActivities) {
            await this.saveActivityBudget(id, approverId);
        }

        for (const lineId of lineIdsWithActualChanges) {
            await this.saveBudgetItem(lineId, approverId);
        }
    }

    public async saveLine(lineId: string, approverId: number): Promise<void> {
        const activity = this.getActivityByLineId(lineId);

        await Promise.all([this.saveActivityBudget(activity.id, approverId), this.saveBudgetItem(lineId, approverId)]);
    }

    public async createPlanCorrection(approverId: number, descriptors: BudgetTransferDesciptor[]): Promise<void> {
        const params: TransferPlanFundsForm[] = this.makeTransferPlanParams(approverId, descriptors);

        for (const paramsItem of params) {
            await BudgetItemApi.transferPlanFunds(paramsItem);
        }
    }

    private async saveActivityBudget(activityId: string, approverId: number): Promise<void> {
        const changes = this.getActivityBudgetChanges(activityId);

        if (changes.length) {
            const params = this.makeUpdateActivityBudgetParams(changes, approverId);

            await ActivityBudgetApi.updateActivityBudget(params);
        }
    }

    private async saveBudgetItem(id: string, approverId: number): Promise<void> {
        const changes = this.getBudgetItemChanges(id);

        if (changes.length) {
            const params = this.makeUpdateBudgetItemParams(changes, approverId);

            await BudgetItemApi.updateBudgetItem(params);
        }
    }

    private makeUpdateActivityBudgetParams(changes: UnsavedChange[], approverId: number): UpdateActivityBudgetForm {
        const nameChange = changes.find((item) => item.columnName == ColumnName.ActivityName);
        const activity = this.getActivityByLineId(nameChange.budgetItemId);

        const params: UpdateActivityBudgetForm = {
            id: activity.id,
            name: nameChange.value as string,
            expertId: approverId,
        };

        return params;
    }

    private makeUpdateBudgetItemParams(changes: UnsavedChange[], approverId: number): UpdateBudgetItemForm {
        const { budgetColumns } = this.store;

        const { budgetItemId } = lodash.first(changes);

        const commentChange = changes.find((item) => item.columnName == ColumnName.Comment);
        const sapCommentChange = changes.find((item) => item.columnName == ColumnName.SapComment);
        const sapZnsChange = changes.find((item) => item.columnName == ColumnName.SapZns);
        const sapCorrectionNumberChange = changes.find((item) => item.columnName == ColumnName.SapCorrectionNumber);
        const lastYearFactChange = changes.find((item) => item.columnName == ColumnName.LastYearFact);
        const responsibleChange = changes.find((item) => item.columnName == ColumnName.Responsible);
        const startChange = changes.find((item) => item.columnName == ColumnName.StartDate);
        const endChange = changes.find((item) => item.columnName == ColumnName.EndDate);
        const businessTargetChange = changes.find((item) => item.columnName == ColumnName.BusinessGoal);
        const customerNameChange = changes.find((item) => item.columnName == ColumnName.Customer);

        const dictionariesChanges = changes.filter((item) => item.columnName.startsWith('dictionary'));

        const planChanged = changes.filter((item) => PLANNED_COLUMN_NAMES.includes(item.columnName));
        const factChanged = changes.filter((item) => FACT_COLUMN_NAMES.includes(item.columnName));
        const reserveChanged = changes.filter((item) => RESERVED_COLUMN_NAMES.includes(item.columnName));

        const params: UpdateBudgetItemForm = {
            id: budgetItemId,
            expertId: approverId,
        };

        if (commentChange) {
            params.comment = commentChange.value as string;
        }

        if (sapCommentChange) {
            params.sapComment = sapCommentChange.value as string;
        }

        if (sapZnsChange) {
            params.sapZns = sapZnsChange.value as string;
        }

        if (sapCorrectionNumberChange) {
            params.sapNumber = sapCorrectionNumberChange.value as string;
        }

        if (lastYearFactChange) {
            params.previousFunds = Number(lastYearFactChange.value as string) * 100.0;
        }

        if (responsibleChange) {
            const packedIds = responsibleChange.value as string;
            const ids = packedIds ? packedIds.split(',').map((id) => parseInt(id, 10)) : null;

            params.responsibleIds = ids;
        }

        if (startChange) {
            params.realizationStart = startChange.value as Date;
        }

        if (endChange) {
            params.realizationEnd = endChange.value as Date;
        }

        if (businessTargetChange) {
            params.businessTarget = businessTargetChange.value as string;
        }

        if (customerNameChange) {
            params.customerName = customerNameChange.value as string;
        }

        if (dictionariesChanges.length) {
            params.dictionary = dictionariesChanges.reduce((acc, change) => {
                const column = budgetColumns[change.columnName];

                const dictionaryType = column.metaData.dictionaryType;

                return {
                    ...acc,
                    [dictionaryType]: change.value,
                };
            }, {} as { [key in DictionaryType]: string });
        }

        if (planChanged.length) {
            params.plannedFunds = planChanged.reduce(moneyRegularTransformer, {});
        }

        if (reserveChanged.length) {
            const reserveCorrectionFunds = this.getReserveCorrectionFunds(budgetItemId);
            const unsavedFunds = reserveChanged.reduce(moneyFormulaTransformer, {});

            params.reservedFunds = { ...reserveCorrectionFunds, ...unsavedFunds };
        }

        if (factChanged.length) {
            params.factFunds = factChanged.reduce(moneyFormulaTransformer, {});
        }

        return params;

        function moneyRegularTransformer(acc: Partial<Funds>, change: UnsavedChange): Partial<Funds> {
            return {
                ...acc,
                [MONTH_BY_COLUMN_NAMES[change.columnName]]: moneyClipper(change.value as any as number),
            };
        }

        function moneyFormulaTransformer(acc: Partial<Funds>, change: UnsavedChange): Partial<Funds> {
            return {
                ...acc,
                [MONTH_BY_COLUMN_NAMES[change.columnName]]: moneyClipper(
                    change.value ? Utils.calculateCurrencyFormula(change.value as any as string) : 0,
                ),
            };
        }

        function moneyClipper(value: number): number {
            return +(Number(value) * 100.0).toFixed(0);
        }
    }

    private getReserveCorrectionFunds(budgetItemId: string): Funds {
        const { reserveCorrections } = this.store;

        const reserveCorrection = lodash.first(reserveCorrections[budgetItemId] || []);

        return reserveCorrection ? (reserveCorrection.data.params as Funds) : undefined;
    }

    private makeTransferPlanParams(expertId: number, descriptors: BudgetTransferDesciptor[]): TransferPlanFundsForm[] {
        const { componentState, participatorItemId, participatorComment } = this.store;

        const isExternalTransfer = isExternalTransferState(componentState);

        let result: TransferPlanFundsForm[] = [];
        if (isExternalTransfer) {
            // expecting single correction
            const descriptor = descriptors[0];

            const params: TransferPlanFundsForm = {
                value: descriptor.amount,
                expertId,
                dictionaryId: participatorItemId,
                comment: participatorComment,
            };
            if (componentState === ComponentState.ExternalIncomeTransfer) {
                params.acceptorId = descriptor.to.lineId;
                params.acceptorMonth = MONTH_BY_COLUMN_NAMES[descriptor.to.columnName];
            } else {
                params.donorId = descriptor.from.lineId;
                params.donorMonth = MONTH_BY_COLUMN_NAMES[descriptor.from.columnName];
            }

            result.push(params);
        } else {
            result = descriptors.map((descriptor) => ({
                value: descriptor.amount,
                expertId,
                acceptorId: descriptor.to.lineId,
                acceptorMonth: MONTH_BY_COLUMN_NAMES[descriptor.to.columnName],
                donorId: descriptor.from.lineId,
                donorMonth: MONTH_BY_COLUMN_NAMES[descriptor.from.columnName],
                comment: participatorComment,
            }));
        }

        return result;
    }

    private getActivityByLineId(lineId: string): ActivityBudget {
        const { budgetItems } = this.store;

        const budgetItem = budgetItems.find((budgetItem) => budgetItem.id === lineId);

        return budgetItem.activity;
    }

    private getActivityBudgetChanges(activityId: string): UnsavedChange[] {
        const { unsavedChanges, budgetItems } = this.store;

        const budgetItem = budgetItems.find((budgetItem) => budgetItem.activity.id === activityId);

        return (unsavedChanges[budgetItem.id] || []).filter((change) =>
            ACTIVITY_FIELDS_COLUMN_NAMES.includes(change.columnName),
        );
    }

    private getBudgetItemChanges(lineId: string): UnsavedChange[] {
        const { unsavedChanges } = this.store;

        const budgetItemColumns = [
            ...BUDGET_ITEM_FIELDS_COLUMN_NAMES,
            ...PLANNED_COLUMN_NAMES,
            ...FACT_COLUMN_NAMES,
            ...RESERVED_COLUMN_NAMES,
        ];

        return (unsavedChanges[lineId] || []).filter(
            (change) => budgetItemColumns.includes(change.columnName) || change.columnName.startsWith('dictionary'),
        );
    }

    private get store(): StoreProps {
        const storeState = store.getState();

        const {
            pageData: {
                activityBudgets,
                corrections: { reserveCorrections },
                budgetColumns,
            },
            computedData: { pageBudgetItems, lineIdsWithActualChanges },
        } = getBudgetExecutionPageState(storeState);
        const {
            controls: { componentState },
            participatorData: { participatorItemId, participatorComment },
        } = getBudgetTransferMenuState(storeState);

        return {
            activityBudgets,
            budgetItems: pageBudgetItems,
            reserveCorrections,
            unsavedChanges: getUnsavedChanges(storeState),
            componentState,
            participatorItemId,
            participatorComment,
            lineIdsWithActualChanges,
            budgetColumns: budgetColumns.byName,
        };
    }
}
