import { get, includes, keys, isEqual, dropWhile, drop } from 'lodash';

import {
    AppliedFiltersNames,
    ColumnData,
    ColumnFiltersLoadingStatus,
    ColumnName,
    ColumnsWithSameData,
    DateCellValue,
    Filters,
    NumericCellValue,
    TableLine,
    UnsavedChange,
    FilterKey,
    PLANNED_COLUMN_NAMES,
    FACT_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
    MONTH_BY_COLUMN_NAMES,
    ColumnNameToFilterMap,
} from '@store/budgetExecution/types';
import { DictionaryType, PlainDictionary } from '@mrm/dictionary';
import { Correction, CorrectionType, Month } from '@mrm/budget';
import { LoadingStatus } from '@store/commonTypes';

export const buildFunctionForGetSelectedDictionaryItemByColumn =
    ({
        changes,
        line,
        dictionaries,
        lineCorrections,
    }: {
        line: TableLine;
        changes: UnsavedChange[];
        dictionaries: { [dictionaryType: string]: PlainDictionary[] };
        lineCorrections: Correction<CorrectionType.BudgetItem>[];
    }) =>
    (column: ColumnData): PlainDictionary => {
        const dictionaryType = get(column, 'metaData.dictionaryType') as DictionaryType;

        const unsavedChange = changes.find((item) => {
            const isBudgetItemMatched = item.budgetItemId === line.id;

            const columnsToChange: ColumnName[] = ColumnsWithSameData[item.columnName] || [item.columnName];
            const isColumnMatched = columnsToChange.some((columnName) => columnName === column.name);

            return isBudgetItemMatched && isColumnMatched;
        });

        const selectedId = getSelectedColumnValue({
            change: unsavedChange,
            line,
            column,
            lineCorrections,
            dictionaries: dictionaries[dictionaryType],
        });

        const currentDictionaries = dictionaries[dictionaryType];
        return selectedId && currentDictionaries ? currentDictionaries.find((item) => item.id == selectedId) : null;
    };

export const getCorrectionDictionaryId = (
    budgetItemCorrections: Correction<CorrectionType.BudgetItem>[],
    dictionaries: PlainDictionary[],
): string => {
    let dictionaryId: string = null;

    for (let i = 0; i < budgetItemCorrections.length && !dictionaryId; i++) {
        const { dictionaryIds } = budgetItemCorrections[i].data.params;

        if (dictionaryIds) {
            dictionaryId = dictionaryIds.find((id) => dictionaries.some((item) => item.id == id));
        }
    }

    return dictionaryId;
};

interface GetSelectedColumnValueParams {
    change: UnsavedChange;
    line: TableLine;
    column: ColumnData;
    lineCorrections: Correction<CorrectionType.BudgetItem>[];
    dictionaries: PlainDictionary[];
}

export const getSelectedColumnValue = ({
    change,
    line,
    column,
    lineCorrections,
    dictionaries,
}: GetSelectedColumnValueParams): string | number | Date | NumericCellValue | DateCellValue => {
    const correctionDictionaryId = getCorrectionDictionaryId(lineCorrections, dictionaries);

    if (change) {
        return change.value;
    }

    if (correctionDictionaryId) {
        return correctionDictionaryId;
    }

    return line.fields[column.name];
};

interface UpdateAppliedFiltersNamesUseAllFiltersParams {
    filters: Filters;
    appliedFiltersNames: AppliedFiltersNames;
}

interface UpdateAppliedFiltersNamesUseTargetFilterParams {
    targetFilter: TargetFilter;
    appliedFiltersNames: AppliedFiltersNames;
}

interface TargetFilter {
    name: FilterKey;
    values: { [key: string]: boolean };
}

export const updateAppliedFiltersNamesUseAllFilters = ({
    filters,
    appliedFiltersNames,
}: UpdateAppliedFiltersNamesUseAllFiltersParams): AppliedFiltersNames => {
    return keys(filters).reduce((newAppliedFiltersNames, filterName: FilterKey) => {
        return updateAppliedFiltersNamesUseTargetFilter({
            targetFilter: {
                name: filterName,
                values: filters[filterName],
            },
            appliedFiltersNames: newAppliedFiltersNames,
        });
    }, appliedFiltersNames);
};

export const updateAppliedFiltersNamesUseTargetFilter = ({
    targetFilter,
    appliedFiltersNames,
}: UpdateAppliedFiltersNamesUseTargetFilterParams): AppliedFiltersNames => {
    const appliedFilterValues = getAppliedFilterValues(targetFilter.values);

    const hasTargetFilterValues = keys(appliedFilterValues).length;
    const isTargetFilterApplied = appliedFiltersNames.some((value) => value === targetFilter.name);

    if (hasTargetFilterValues && !isTargetFilterApplied) {
        return [...appliedFiltersNames, targetFilter.name];
    }

    if (!hasTargetFilterValues && isTargetFilterApplied) {
        return appliedFiltersNames.filter((filterColumnName) => filterColumnName !== targetFilter.name);
    }

    return appliedFiltersNames;
};

export const getAppliedFilterValues = (value: Record<string, boolean>): Record<string, boolean> => {
    return keys(value).reduce((prev, key) => {
        return value[key]
            ? {
                  ...prev,
                  [key]: value[key],
              }
            : prev;
    }, {});
};

export interface UpdateColumnFilterLoadingStatusParams {
    filtersGroup: {
        prev: Filters;
        current: Filters;
    };
    appliedFiltersNamesGroup: {
        prev: AppliedFiltersNames;
        current: AppliedFiltersNames;
    };
    columnFiltersLoadingStatus: ColumnFiltersLoadingStatus;
}

export const updateColumnFilterLoadingStatus = ({
    filtersGroup,
    appliedFiltersNamesGroup,
    columnFiltersLoadingStatus,
}: UpdateColumnFilterLoadingStatusParams): ColumnFiltersLoadingStatus => {
    const changedColumnFilterNames = keys(filtersGroup.current).reduce((changedFilterColumnNames, filterColumnName) => {
        const prevFilter = filtersGroup.prev[filterColumnName];
        const currentFilter = filtersGroup.current[filterColumnName];

        const haveChanges = !isEqual(currentFilter, prevFilter);

        return haveChanges ? [...changedFilterColumnNames, filterColumnName] : changedFilterColumnNames;
    }, []);

    const columnNamesForUpdatingLoadingStatus = drop(
        dropWhile(appliedFiltersNamesGroup.prev, (appliedFilterName) => {
            return !includes(changedColumnFilterNames, appliedFilterName);
        }),
    );

    const updatedFiltersLoadingStatus = keys(columnFiltersLoadingStatus).reduce(
        (newColumnFiltersLoadingStatus, columnName) => {
            if (includes(columnNamesForUpdatingLoadingStatus, columnName)) {
                return {
                    ...newColumnFiltersLoadingStatus,
                    [columnName]: LoadingStatus.NOT_LOADED,
                };
            }

            if (!includes(appliedFiltersNamesGroup.current, columnName)) {
                return {
                    ...newColumnFiltersLoadingStatus,
                    [columnName]: LoadingStatus.NOT_LOADED,
                };
            }

            return {
                ...newColumnFiltersLoadingStatus,
                [columnName]: columnFiltersLoadingStatus[columnName],
            };
        },
        {},
    );

    return updatedFiltersLoadingStatus;
};

const DictionaryTypeToColumnNameMap = {
    [DictionaryType.ActivityType]: ColumnName.ActivityType,
    [DictionaryType.CostCenter]: ColumnName.CostCenter,
    [DictionaryType.CostDirection]: ColumnName.CostDirecrtion,
    [DictionaryType.LocationDriver]: ColumnName.LocationDriver,
};

export const getColumnNameFromDictionaryType = (dictionaryType: DictionaryType): ColumnName => {
    return DictionaryTypeToColumnNameMap[dictionaryType] || dictionaryType;
};

export function getColumnByMonth(month: Month, column: 'plan' | 'fact' | 'reserve'): ColumnName {
    let columnsToCheck: ColumnName[];
    switch (column) {
        case 'plan':
            columnsToCheck = PLANNED_COLUMN_NAMES;
            break;
        case 'fact':
            columnsToCheck = FACT_COLUMN_NAMES;
            break;
        case 'reserve':
            columnsToCheck = RESERVED_COLUMN_NAMES;
            break;
        default:
            console.warn(`Unknown column type: ${column}`);
            columnsToCheck = [];
            break;
    }

    return columnsToCheck.find((column) => MONTH_BY_COLUMN_NAMES[column] === month);
}

export function convertColumnToFilterKey(column: string, params?: { addIdMarker?: boolean }) {
    const dictionaryCodeColumn = column.match(/dictionary-code_([a-z_]*)/)?.[1];
    const dictionaryColumn = column.match(/dictionary_([a-z_]*)/)?.[1];

    return dictionaryCodeColumn
        ? `dictionary.${dictionaryCodeColumn}.code`
        : dictionaryColumn
        ? `dictionary.${dictionaryColumn}${params?.addIdMarker ? '.id' : ''}`
        : ColumnNameToFilterMap[column];
}
