import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { BudgetItem } from '@mrm/budget';

import { BudgetTableFiltersState as State } from './types';

import * as actions from './actions/sync';

class Reducer {
    public static makeInitialState(): State {
        return {
            filteredData: {
                activityBudgets: new Set(),
                budgetItems: new Set(),
            },
            items: {
                dictionary: {},
            },
            selectedItems: {
                dictionary: {},
            },
            appliedFilters: {
                dictionary: {},
            },
        };
    }

    public static deepMergeState<L extends keyof State>(key: L) {
        return function reducer(state: State, data: State[L]): State {
            return { ...state, [key]: { ...state[key], ...data } };
        };
    }

    public static applyLoadedFilters(state: State, filteredBudgetItems: BudgetItem[]): State {
        const activityBudgets = new Set<string>();
        const budgetItems = new Set<string>();

        filteredBudgetItems.forEach((bi) => {
            activityBudgets.add(bi.activity.id);
            budgetItems.add(bi.id);
        });

        return {
            ...state,
            filteredData: {
                activityBudgets,
                budgetItems,
            },
            appliedFilters: state.selectedItems,
        };
    }

    public static resetSelectedFilters(state: State): State {
        const { filteredData, selectedItems } = Reducer.makeInitialState();

        return {
            ...state,
            filteredData,
            selectedItems,
            appliedFilters: selectedItems,
        };
    }
}

export const budgetTableFiltersReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.applyLoadedFilters, Reducer.applyLoadedFilters)
    .case(actions.setItems, Reducer.deepMergeState('items'))
    .case(actions.setSelectedItems, Reducer.deepMergeState('selectedItems'))
    .case(actions.resetSelectedFilters, Reducer.resetSelectedFilters);
