import * as lodash from 'lodash';

import { BudgetItem } from '@mrm/budget';
import {
    ColumnData,
    ColumnName,
    CustomCellType,
    TableLine,
    ChangeList,
    CellPosition,
    DropdownOptions,
    BudgetColumns,
} from '@store/budgetPlanning/types';
import { DropdownCellOptions } from '../CellTypes';

import {
    InputCellParamsCreator,
    DropdownCellParamsCreator,
    DatepickerCellParamsCreator,
    TextCellParamsCreator,
} from './CellTypes';

export enum CellBackgroundColor {
    UnsavedChange = '#fffad8',
}

export interface ActualValue {
    value: string;
    color?: CellBackgroundColor;
}

export type LineCellsParams = { [columnName: string]: CellParams };

export interface CellParams {
    title?: string;
    tooltip?: string;
    value?: string | Date;
    originalValue?: string | Date;
    minDate?: Date;
    maxDate?: Date;
    options?: DropdownCellOptions[];
    isClickable?: boolean;
    isSelected?: boolean;
    isHovered?: boolean;
    bgColor?: CellBackgroundColor;
    displayValidationError?: boolean;
    disabled?: boolean;
    allowMultipleItems?: boolean;
    cellInfo?: string | JSX.Element;
}

export interface CellsParamsByLine {
    [lineId: string]: LineCellsParams;
}

interface Props {
    lines: TableLine[];
    dropdownsOptions: { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } };
    unsavedChanges: ChangeList;
    validationStatus: boolean;
    budgetItemsByActivityId: lodash.Dictionary<BudgetItem[]>;
    budgetColumns: BudgetColumns;
}

export class LayerManager {
    private static instance: LayerManager;

    private lines: TableLine[];
    private dropdownsOptions: { [lineId: string]: { [columnName: string]: DropdownCellOptions[] } };
    private unsavedChanges: ChangeList;
    private cellsParamsByLines: CellsParamsByLine;
    private validationStatus: boolean;
    private budgetItemsByActivityId: lodash.Dictionary<BudgetItem[]>;
    private budgetColumns: BudgetColumns;

    public static getInstance(props?: Props): LayerManager {
        if (!this.instance) {
            this.instance = new LayerManager();
        }

        if (props) {
            this.instance.init(props);
        }

        return this.instance;
    }

    public makeTableCellsParams(): CellsParamsByLine {
        const result = {};

        this.lines.forEach((line) => {
            result[line.id] = this.makeLineCellsParams(line, true);
        });

        return result;
    }

    public makeTalbeCellsParamsForXLSXExport(lines: TableLine[], dropdownOptions: DropdownOptions): CellsParamsByLine {
        const result = {};

        const savedDropwonOptions = this.dropdownsOptions;
        this.dropdownsOptions = dropdownOptions;

        lines.forEach((line) => {
            result[line.id] = this.makeLineCellsParams(line, false);
        });

        this.dropdownsOptions = savedDropwonOptions;

        return result;
    }

    public getCellsParams(): { [lineId: string]: LineCellsParams } {
        return this.cellsParamsByLines;
    }

    public applyUnsavedChanges(changes: ChangeList): void {
        const lineIds = lodash.keys({ ...this.unsavedChanges, ...changes });

        const cellsToUpdate: CellPosition[] = [];

        lineIds.forEach((lineId) => {
            const removedChanges = lodash.difference(this.unsavedChanges[lineId], changes[lineId]);
            const newChanges = lodash.difference(changes[lineId], this.unsavedChanges[lineId]);

            const changedCells = [...removedChanges, ...newChanges].map((change) => ({
                lineId: change.budgetItemId,
                columnName: change.columnName,
            }));

            cellsToUpdate.push(...changedCells);
        });

        this.unsavedChanges = changes;

        this.updateCellsParams(cellsToUpdate);
    }

    public updateDropdownsOptions(dropdownsOptions: {
        [lineId: string]: { [columnName: string]: DropdownCellOptions[] };
    }): void {
        const changedLinesIds: string[] = [];

        lodash.forEach(dropdownsOptions, (item, lineId) => {
            if (item !== this.dropdownsOptions[lineId]) {
                changedLinesIds.push(lineId);
            }
        });

        this.dropdownsOptions = dropdownsOptions;

        changedLinesIds.forEach((lineId) => {
            const line = this.lines.find((item) => item.id == lineId);

            this.budgetColumns.byCustomCellType[CustomCellType.Dropdown].forEach((column) => {
                this.cellsParamsByLines[lineId][column.name] = this.makeCellParams(line, column);
            });
        });
    }

    private init(props: Props): void {
        this.lines = props.lines;
        this.dropdownsOptions = props.dropdownsOptions;
        this.unsavedChanges = props.unsavedChanges;
        this.validationStatus = props.validationStatus;
        this.budgetItemsByActivityId = props.budgetItemsByActivityId;
        this.budgetColumns = props.budgetColumns;

        this.cellsParamsByLines = this.makeTableCellsParams();
    }

    private updateCellsParams(cells: CellPosition[]): void {
        cells.forEach((cellPosition) => {
            if (cellPosition.columnName && cellPosition.lineId) {
                const line = this.lines.find((item) => item.id == cellPosition.lineId);
                const column = this.budgetColumns.byName[cellPosition.columnName];

                if (line) {
                    const newParams = this.makeCellParams(line, column);

                    this.saveCellParams(line.id, column.name, newParams);

                    this.updateRelatedCells(line, column);
                }
            }
        });
    }

    private updateRelatedCells(line: TableLine, column: ColumnData): void {
        const relatedColumns = this.getRelatedColumnNames(column.name);

        relatedColumns.forEach((columnName) => {
            const relatedColumn = this.budgetColumns.byName[columnName];

            const relatedCellParams = this.makeCellParams(line, relatedColumn);

            this.saveCellParams(line.id, columnName, relatedCellParams);
        });
    }

    private makeLineCellsParams(line: TableLine, applyChanges: boolean): LineCellsParams {
        const lineCellsParams: LineCellsParams = {};

        this.budgetColumns.all.forEach((column) => {
            lineCellsParams[column.name] = this.makeCellParams(line, column, applyChanges);
        });

        return lineCellsParams;
    }

    private makeCellParams(line: TableLine, column: ColumnData, applyChanges = true): CellParams {
        let cellParams: CellParams;

        switch (column.customCellType) {
            case CustomCellType.Input:
                cellParams = this.makeInputCellParams(line, column, applyChanges);
                break;

            case CustomCellType.Dropdown:
                cellParams = this.makeDropdownCellParams(line, column, applyChanges);
                break;

            case CustomCellType.Datepicker:
                cellParams = this.makeDatepickerCellParams(line, column, applyChanges);
                break;

            default:
                cellParams = this.makeTextCellParams(line, column, applyChanges);
                break;
        }

        return cellParams;
    }

    private makeInputCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const params = {
            line,
            column,
            validationStatus: this.validationStatus,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
            budgetItemsByActivityId: this.budgetItemsByActivityId,
        };

        const creactor = new InputCellParamsCreator(params);

        return creactor.makeCellParams();
    }

    private makeDropdownCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const params = {
            line,
            column,
            dropdownsOptions: this.dropdownsOptions[line.id],
            validationStatus: this.validationStatus,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
        };

        const creactor = new DropdownCellParamsCreator(params);

        return creactor.makeCellParams();
    }

    private makeDatepickerCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const params = {
            line,
            column,
            columns: this.budgetColumns,
            validationStatus: this.validationStatus,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
        };

        const creactor = new DatepickerCellParamsCreator(params);

        return creactor.makeCellParams();
    }

    private makeTextCellParams(line: TableLine, column: ColumnData, applyChanges: boolean): CellParams {
        const params = {
            line,
            column,
            columns: this.budgetColumns,
            unsavedChanges: (applyChanges && this.unsavedChanges) || {},
        };

        const creactor = new TextCellParamsCreator(params);

        return creactor.makeCellParams();
    }

    private saveCellParams(lineId: string, columnName: ColumnName, newCellParams: CellParams): void {
        this.cellsParamsByLines[lineId][columnName] = newCellParams;
    }

    private getRelatedColumnNames(columnName: ColumnName): ColumnName[] {
        let relatedColumnNames: ColumnName[] = [];

        this.budgetColumns.all.forEach((column) => {
            if (
                lodash.get(column, 'metaData.relatedColumns') &&
                lodash.includes(column.metaData.relatedColumns, columnName)
            ) {
                relatedColumnNames.push(column.name);
            }
        });

        switch (columnName) {
            case ColumnName.StartDate:
                relatedColumnNames = [ColumnName.EndDate];
                break;

            case ColumnName.EndDate:
                relatedColumnNames = [ColumnName.StartDate];
                break;
        }

        return relatedColumnNames;
    }
}
