import * as lodash from 'lodash';
import autobind from 'autobind-decorator';

import { Correction, CorrectionType, Funds } from '@mrm/budget';
import {
    TableLine,
    ColumnData,
    ColumnName,
    ChangeList,
    CellValueType,
    PLANNED_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
} from '@store/budgetExecution/types';
import { CellParams, CellBackgroundColor, ActualValue } from '../LayerManager';

import { getFieldValue, getMonthByIndex } from './Utils';

import { MoneyFormatter, Money, Utils } from '@common/Utils';

interface Props {
    line: TableLine;
    column: ColumnData;
    unsavedChanges: ChangeList;
    planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    incomeExternalPlanCorrections: Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[];
    outcomeExternalPlanCorrections: Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[];
    reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
    isDisabled: boolean;
    budgetColumnsByName: Record<string, ColumnData>;
}

export class TextCellParamsCreator {
    private line: TableLine;
    private column: ColumnData;
    private unsavedChanges: ChangeList;
    private planCorrections: Correction<CorrectionType.PlanFundsTransfer>[];
    private incomeExternalPlanCorrections: Correction<CorrectionType.IncomeExternalPlanFundsTransfer>[];
    private outcomeExternalPlanCorrections: Correction<CorrectionType.OutcomeExternalPlanFundsTransfer>[];
    private reserveCorrections: Correction<CorrectionType.ReservedFunds>[];
    private isDisabled: boolean;
    private budgetColumnsByName: Record<string, ColumnData>;

    constructor(props: Props) {
        const {
            line,
            column,
            unsavedChanges,
            planCorrections,
            incomeExternalPlanCorrections,
            outcomeExternalPlanCorrections,
            reserveCorrections,
            isDisabled,
            budgetColumnsByName,
        } = props;

        this.line = line;
        this.column = column;
        this.unsavedChanges = unsavedChanges;
        this.planCorrections = planCorrections;
        this.incomeExternalPlanCorrections = incomeExternalPlanCorrections;
        this.outcomeExternalPlanCorrections = outcomeExternalPlanCorrections;
        this.reserveCorrections = reserveCorrections;
        this.isDisabled = isDisabled;
        this.budgetColumnsByName = budgetColumnsByName;
    }

    public makeCellParams(): CellParams {
        let title: string;
        let tooltip = '';
        let value: string;
        let color: CellBackgroundColor;

        if (this.column.metaData && this.column.metaData.calculateActualValue) {
            const actualValue: ActualValue = this.column.metaData.calculateActualValue(this.getValueByColumnName);

            value = actualValue.value;
            color = actualValue.color;
        } else {
            value = this.getBaseValue(this.column);
        }

        title = value;
        tooltip = value;

        if (this.column.valueType == CellValueType.Currency) {
            const valueMoney = Money.fromStringCopecks(value);
            title = MoneyFormatter.toThousands(valueMoney, { hideCaption: true });
            tooltip = MoneyFormatter.toRoubles(valueMoney);
        }

        return {
            title,
            tooltip,
            value,
            bgColor: color,
            disabled: this.isDisabled,
        };
    }

    private getUnsavedValue(column: ColumnData): string {
        const changes = this.unsavedChanges[this.line.id] || [];

        const unsavedChange = changes.find(
            (item) => item.budgetItemId == this.line.id && item.columnName == column.name,
        );

        if (!unsavedChange) {
            return undefined;
        }

        let value = unsavedChange.value as string;

        if (this.column.valueType === CellValueType.Currency) {
            value = value && String(Utils.calculateCurrencyFormula(value) * 100.0);
        }

        return value;
    }

    private getCorrectionValue(column: ColumnData): string {
        let value: string;

        const isPlanColumn = lodash.includes(PLANNED_COLUMN_NAMES, column.name);
        const isReserveColumn = lodash.includes(RESERVED_COLUMN_NAMES, column.name);

        if (isPlanColumn) {
            value = this.getPlanCorrectionValue(column);
        }

        if (isReserveColumn) {
            value = this.getReserveCorrectionValue(column);
        }

        return value;
    }

    private getPlanCorrectionValue(column: ColumnData): string {
        const isDonor = this.isDonor(this.line, column);
        const isAcceptor = this.isAcceptor(this.line, column);

        if (!isDonor && !isAcceptor) {
            return undefined;
        }

        const baseValue = parseFloat(this.getBaseValue(column)) || 0;

        const monthIndex = column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        const incomeValues = [...this.planCorrections, ...this.incomeExternalPlanCorrections].map((item) => {
            const isAcceptorCorrection =
                item.data.params.acceptorId == this.line.id && item.data.params.acceptorMonth === month;

            return isAcceptorCorrection ? item.data.params.value : 0;
        });

        const outcomeValues = [...this.planCorrections, ...this.outcomeExternalPlanCorrections].map((item) => {
            const isAcceptorCorrection =
                item.data.params.donorId == this.line.id && item.data.params.donorMonth === month;

            return isAcceptorCorrection ? item.data.params.value : 0;
        });

        const delta = lodash.sum(incomeValues) - lodash.sum(outcomeValues);

        return String(baseValue + delta);
    }

    private getReserveCorrectionValue(column: ColumnData): string {
        let correctionValue: string;

        const lastReserveCorrection = lodash.last(lodash.sortBy(this.reserveCorrections, (item) => item.creationTime));

        if (lastReserveCorrection) {
            const baseValue = this.getBaseValue(column);

            const funds: Funds = lastReserveCorrection.data.params;
            const monthIndex = column.metaData.month - 1;
            const month = getMonthByIndex(monthIndex);

            const valueChanged = funds[month] && funds[month].toString() !== baseValue;

            if (valueChanged) {
                correctionValue = funds[month].toString();
            }
        }

        return correctionValue || undefined;
    }

    private getBaseValue(column: ColumnData): string {
        return getFieldValue(this.line.fields[column.name], column.valueType) as string;
    }

    @autobind
    private getValueByColumnName(columnName: ColumnName): ActualValue {
        const column = this.budgetColumnsByName[columnName];

        let value: string;
        let color: CellBackgroundColor;

        const unsavedValue = this.getUnsavedValue(column);

        if (unsavedValue !== undefined) {
            value = unsavedValue;
            color = CellBackgroundColor.UnsavedChange;
        } else {
            const correctionValue = this.getCorrectionValue(column);

            if (correctionValue !== undefined) {
                value = correctionValue;
                color = CellBackgroundColor.SavedChange;
            } else {
                const baseValue = this.getBaseValue(column);

                value = baseValue;
                color = null;
            }
        }

        return {
            value,
            color,
        };
    }

    private isDonor(line: TableLine, column: ColumnData): boolean {
        const corrections = [...this.planCorrections, ...this.outcomeExternalPlanCorrections];

        const monthIndex = column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        return corrections.some((item) => {
            const monthIsEqual = item.data.params.donorMonth === month;
            const lineIdIsEqual = item.data.params.donorId == line.id;

            return monthIsEqual && lineIdIsEqual;
        });
    }

    private isAcceptor(line: TableLine, column: ColumnData): boolean {
        const corrections = [...this.planCorrections, ...this.incomeExternalPlanCorrections];

        const monthIndex = column.metaData.month - 1;
        const month = getMonthByIndex(monthIndex);

        return corrections.some((item) => {
            const monthIsEqual = item.data.params.acceptorMonth === month;
            const lineIdIsEqual = item.data.params.acceptorId == line.id;

            return monthIsEqual && lineIdIsEqual;
        });
    }
}
