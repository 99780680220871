import { actionCreatorFactory } from 'typescript-fsa';
import { BudgetItem } from '@mrm/budget';

import {
    TransferPlanFundsFormState,
    ExpertsState,
    CellsState,
    ComponentState,
    ControlsState,
    CellPosition,
    TransferDescriptorsState,
} from '../types';

const actionCreator = actionCreatorFactory('TRANSFER_PLAN_FUNDS_FORM').async;

const setComponentState = actionCreator<ComponentState, Partial<TransferPlanFundsFormState>, Error>(
    'SET_COMPONENT_STATE',
);
const loadExperts = actionCreator<null, ExpertsState, Error>('LOAD_EXPERTS');
const onCellClick = actionCreator<CellPosition, CellsState, Error>('ON_CELL_CLICK');
const toggleLineStatus = actionCreator<BudgetItem, Partial<CellsState>, Error>('TOGGLE_LINE_STATUS');
const initPlanTransfer = actionCreator<null, void, Error>('INIT_PLAN_TRANSFER');
const toggleInternalTransitionDirectrion = actionCreator<null, Partial<ControlsState>, Error>(
    'TOGGLE_INTERNAL_TRANSITION_DIRECTION',
);
const updateIsHoveredLineClickable = actionCreator<string, Partial<ControlsState>, Error>(
    'UPDATE_IS_HOVERED_LINE_CLICKABLE',
);
const updateTransferDescriptors = actionCreator<null, TransferDescriptorsState, Error>('UPDATE_TRANSFER_DESCRIPTORS');

export {
    setComponentState,
    loadExperts,
    onCellClick,
    toggleLineStatus,
    initPlanTransfer,
    toggleInternalTransitionDirectrion,
    updateIsHoveredLineClickable,
    updateTransferDescriptors,
};
