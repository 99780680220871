import { bindThunkAction } from 'typescript-fsa-redux-thunk';

import { StoreState } from '@store';

import { BudgetItemApi } from '@api';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

import { getBudgetTableState } from '../selectors';

import { loadTableData } from './dataLoading';

export const updateAfterPlanCorrectons = bindThunkAction<StoreState, string[], void, Error>(
    asyncActions.updateAfterPlanCorrectons,
    async (budgetItemIds, dispatch, getState) => {
        const budgetId = getBudgetTableState(getState()).activeBudgetId;

        const budgetItems = await BudgetItemApi.getBudgetItemList({
            budgetId,
            filter: {
                id: budgetItemIds,
            },
        });

        dispatch(
            actions.syncDataAfterCorrection({
                activityBudgets: [],
                budgetItems,
            }),
        );
    },
);

export const setActiveBudgetId = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.setActiveBudgetId,
    async (budgetId, dispatch, getState) => {
        dispatch(actions.setActiveBudgetId(budgetId));
        dispatch(loadTableData(null));
    },
);
