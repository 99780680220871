import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { compact, concat, flatten } from 'lodash';
import { BudgetItem, TransferPlanFundsForm } from '@mrm/budget';

import { UserApi, BudgetItemApi } from '@api';

import { StoreState } from '@store';
import { LoadingStatus } from '@store/commonTypes';
import { NotificationActionType, NotificationType } from '@store/common/types';
import { setNotification } from '@store/common/actions';
import { getLoginUser, isLoggedUserBudgetExpert } from '@store/user';
import { getBudgetTableState, updateAfterPlanCorrectons } from '@store/upd_budgetPage/budgetTable';

import { MonthUtils } from '@common/Utils';

import * as asyncActions from './actions/async';
import * as actions from './actions/sync';
import { Reducer } from './reducers';
import {
    TransferPlanFundsFormState,
    ComponentState,
    InternalTransferDirection,
    ExpertsState,
    CellPosition,
    UserOrganizationToExpertOrganizationMap,
    TransferDescriptorsState,
    CellsState,
    ControlsState,
} from './types';
import { getBudgetTransferMenuState, isCellAcceptor, isCellDonor, isLineDonor, isLineAcceptor } from './selectors';
import { isCellSelected, isInternalTransferState, isExternalTransferState } from './misc';

export const setComponentState = bindThunkAction<
    StoreState,
    ComponentState,
    Partial<TransferPlanFundsFormState>,
    Error
>(asyncActions.setComponentState, async (componentState, dispatch, getState) => {
    const state = getState();
    const transitionDataState = getBudgetTransferMenuState(state);
    const prevComponentState = transitionDataState.controls.componentState;

    const initialState: TransferPlanFundsFormState = Reducer.makeInitialState();

    const shouldKeepInternalTransferDirection =
        prevComponentState === ComponentState.InternalTransferLineSelection &&
        componentState === ComponentState.InternalTransferCellSelection;
    const shouldResetView =
        componentState === ComponentState.Closed ||
        componentState === ComponentState.ExternalIncomeTransfer ||
        componentState === ComponentState.ExternalOutcomeTransfer ||
        componentState === ComponentState.InternalTransferCellSelection;
    const shouldUpdateTransferDescriptors =
        prevComponentState === ComponentState.InternalTransferLineSelection &&
        componentState === ComponentState.InternalTransferSumEntering;

    if (shouldKeepInternalTransferDirection) {
        return {
            ...initialState,
            controls: {
                ...initialState.controls,
                componentState,
                internalTransferDirection: transitionDataState.controls.internalTransferDirection,
            },
        };
    } else if (shouldResetView) {
        return {
            ...initialState,
            controls: {
                ...initialState.controls,
                componentState,
            },
            experts: {
                ...transitionDataState.experts,
                selectedExpert: null,
            },
        };
    }

    if (shouldUpdateTransferDescriptors) {
        // dellay to allow state update
        setTimeout(() => dispatch(updateTransferDescriptors(null)));
    }

    return {
        ...transitionDataState,
        controls: {
            ...transitionDataState.controls,
            componentState,
        },
    };
});

export const openTransitionMenu = setComponentState.bind(null, ComponentState.InternalTransferCellSelection);
export const closeTransitionMenu = setComponentState.bind(null, ComponentState.Closed);

export const loadExperts = bindThunkAction<StoreState, null, ExpertsState, Error>(
    asyncActions.loadExperts,
    async (_, dispatch, getState) => {
        const state = getState();
        const userOrganizationId = getLoginUser(state).attributes.organizationId;
        let result: ExpertsState = getBudgetTransferMenuState(state).experts;

        if (result.loadingStatus === LoadingStatus.NOT_LOADED) {
            const expertsOrganizationIds = compact(
                concat(UserOrganizationToExpertOrganizationMap[userOrganizationId], userOrganizationId),
            );

            dispatch(actions.startLoadingExperts());
            const entities = await UserApi.getOrganizationExperts({
                organizationIds: expertsOrganizationIds,
            });

            result = {
                loadingStatus: LoadingStatus.LOADED,
                entities,
                selectedExpert: entities[0] ? entities[0].id : null,
            };
        }

        return result;
    },
);

export const onCellClick = bindThunkAction<StoreState, CellPosition, Partial<CellsState>, Error>(
    asyncActions.onCellClick,
    async (cell, dispatch, getState) => {
        const state = getState();
        const {
            controls: { componentState, internalTransferDirection },
            cells: { from: initialFrom, to: initialTo },
        } = getBudgetTransferMenuState(state);

        const isInternalTransfer = isInternalTransferState(componentState);

        const removeCellFromDonor = isCellDonor(state, cell);
        const removeCellFromAcceptor = isCellAcceptor(state, cell);

        const removeLineFromDonor =
            componentState === ComponentState.InternalTransferLineSelection &&
            internalTransferDirection === InternalTransferDirection.ManyToOne &&
            isLineDonor(state, cell.budgetItem.id);
        const removeLineFromAcceptor =
            componentState === ComponentState.InternalTransferLineSelection &&
            internalTransferDirection === InternalTransferDirection.OneToMany &&
            isLineAcceptor(state, cell.budgetItem.id);

        let from: CellPosition[] = initialFrom;
        let to: CellPosition[] = initialTo;
        if (removeLineFromDonor) {
            from = initialFrom.filter((fromCell) => fromCell.budgetItem.id !== cell.budgetItem.id);
        } else if (removeLineFromAcceptor) {
            to = initialTo.filter((toCell) => toCell.budgetItem.id !== cell.budgetItem.id);
        } else if (removeCellFromDonor || removeCellFromAcceptor) {
            from = [];
            to = [];
        } else {
            const donorIsSelected = isCellSelected(initialFrom);
            const acceptorIsSelected = isCellSelected(initialTo);

            const setToCell =
                componentState === ComponentState.ExternalIncomeTransfer ||
                (isInternalTransfer &&
                    internalTransferDirection === InternalTransferDirection.ManyToOne &&
                    !donorIsSelected);
            const setFromCell =
                componentState === ComponentState.ExternalOutcomeTransfer ||
                (isInternalTransfer &&
                    internalTransferDirection === InternalTransferDirection.OneToMany &&
                    !acceptorIsSelected);

            if (setToCell) {
                to = [...to, cell];
            }
            if (setFromCell) {
                from = [...from, cell];
            }
        }

        const result: Partial<CellsState> = { from, to };
        if (isInternalTransfer) {
            const gotoLineSelection =
                (internalTransferDirection === InternalTransferDirection.OneToMany && isCellSelected(from)) ||
                (internalTransferDirection === InternalTransferDirection.ManyToOne && isCellSelected(to));

            if (gotoLineSelection) {
                dispatch(setComponentState(ComponentState.InternalTransferLineSelection));
            } else {
                dispatch(setComponentState(ComponentState.InternalTransferCellSelection));
            }
        } else {
            // dellay to allow state update
            setTimeout(() => dispatch(updateTransferDescriptors(null)), 0);
        }

        return result;
    },
);

export const toggleLineStatus = bindThunkAction<StoreState, BudgetItem, Partial<CellsState>, Error>(
    asyncActions.toggleLineStatus,
    async (budgetItem, dispatch, getState) => {
        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());

        const result: Partial<CellsState> = {};
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                result.to = to.some((toCell) => toCell.budgetItem.id === budgetItem.id)
                    ? to.filter((toCell) => toCell.budgetItem.id !== budgetItem.id)
                    : [
                          ...to,
                          {
                              budgetItem,
                              month: null,
                          },
                      ];
                break;
            case InternalTransferDirection.ManyToOne:
                result.from = from.some((fromCell) => fromCell.budgetItem.id === budgetItem.id)
                    ? from.filter((fromCell) => fromCell.budgetItem.id !== budgetItem.id)
                    : [
                          ...from,
                          {
                              budgetItem,
                              month: null,
                          },
                      ];
                break;
            default:
                break;
        }

        return result;
    },
);

export const initPlanTransfer = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.initPlanTransfer,
    async (_, dispatch, getState) => {
        const state = getState();
        const loginnedUserId = getLoginUser(state).attributes.id;
        const userIsBudgetExpert = isLoggedUserBudgetExpert(state);
        const {
            controls: { componentState },
            cells: { from, to },
            participatorData: { participatorItemId, participatorComment },
            transferDescriptors,
        } = getBudgetTransferMenuState(state);

        const isExternalTransfer = isExternalTransferState(componentState);

        dispatch(actions.setRequestInProgress(true));

        let transferFundParams: TransferPlanFundsForm[] = [];
        if (isExternalTransfer) {
            // expecting single correction
            const descriptor = transferDescriptors[0];

            const params: TransferPlanFundsForm = {
                value: descriptor.amount,
                expertId: loginnedUserId,
                dictionaryId: participatorItemId,
                comment: participatorComment,
            };
            if (componentState === ComponentState.ExternalIncomeTransfer) {
                params.acceptorId = descriptor.to.budgetItem.id;
                params.acceptorMonth = descriptor.to.month;
            } else {
                params.donorId = descriptor.from.budgetItem.id;
                params.donorMonth = descriptor.from.month;
            }

            transferFundParams.push(params);
        } else {
            transferFundParams = transferDescriptors
                .filter((descriptor) => descriptor.amount)
                .map((descriptor) => ({
                    value: descriptor.amount,
                    expertId: loginnedUserId,
                    acceptorId: descriptor.to.budgetItem.id,
                    acceptorMonth: descriptor.to.month,
                    donorId: descriptor.from.budgetItem.id,
                    donorMonth: descriptor.from.month,
                    comment: participatorComment,
                }));
        }

        for (const paramsItem of transferFundParams) {
            await BudgetItemApi.transferPlanFunds(paramsItem);
        }

        const linesToUpdate = compact([...from, ...to].map((cell) => cell.budgetItem.id));

        dispatch(updateAfterPlanCorrectons(linesToUpdate));

        dispatch(
            setNotification({
                type: NotificationType.SUCCESS,
                typeAction: NotificationActionType.CREATE_PLANNED_BUDGET_IN_TRANSFER_MENU_BUDGET,
                comment: 'Корректировка плана бюджета внесена',
            }),
        );

        if (!userIsBudgetExpert) {
            dispatch(
                setNotification({
                    type: NotificationType.SUCCESS,
                    typeAction: NotificationActionType.PLANNED_BUDGET_SEND_TO_APPROVEMENT,
                    comment: 'Корректировка плана бюджета отправлена на согласование',
                }),
            );
        }

        dispatch(closeTransitionMenu());
    },
);

export const toggleInternalTransitionDirectrion = bindThunkAction<StoreState, null, Partial<ControlsState>, Error>(
    asyncActions.toggleInternalTransitionDirectrion,
    async (_, dispatch, getState) => {
        const {
            controls: { internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());

        const result: Partial<ControlsState> = {};
        let needSwapFromTo: boolean;
        switch (internalTransferDirection) {
            case InternalTransferDirection.OneToMany:
                result.internalTransferDirection = InternalTransferDirection.ManyToOne;
                needSwapFromTo = true;
                break;
            case InternalTransferDirection.ManyToOne:
                result.internalTransferDirection = InternalTransferDirection.OneToMany;
                needSwapFromTo = true;
                break;
            default:
                break;
        }

        if (needSwapFromTo) {
            dispatch(
                actions.updateCells({
                    to: from,
                    from: to,
                }),
            );
        }

        return result;
    },
);

export const updateIsHoveredLineClickable = bindThunkAction<StoreState, string, Partial<ControlsState>, Error>(
    asyncActions.updateIsHoveredLineClickable,
    async (lineId, dispatch, getState) => {
        const state = getState();
        const {
            controls: { internalTransferDirection },
        } = getBudgetTransferMenuState(state);

        let isHoveredLineClickable: boolean;
        if (lineId) {
            switch (internalTransferDirection) {
                case InternalTransferDirection.OneToMany:
                    isHoveredLineClickable = true;

                    break;
                case InternalTransferDirection.ManyToOne:
                    console.log('CHECK SKIP');
                    isHoveredLineClickable = true;

                    break;

                // const lineIsEditable = !lines.find((line) => line.id === lineId).isDisabled;

                // if (lineIsEditable) {
                //     const lineCellsParams = cellsParams[lineId];
                //     const acceptorDesc = to.find((toCell) => toCell.budgetItem.id === lineId);

                //     isHoveredLineClickable = PLANNED_COLUMN_NAMES.some((columnName: ColumnName) => {
                //         if (acceptorDesc && acceptorDesc.columnName === columnName) {
                //             return false;
                //         }

                //         return lineCellsParams[columnName].isClickable;
                //     });
                // } else {
                //     isHoveredLineClickable = false;
                // }

                // break;
                default:
                    break;
            }
        } else {
            isHoveredLineClickable = true;
        }

        return { isHoveredLineClickable };
    },
);

const updateTransferDescriptors = bindThunkAction<StoreState, null, TransferDescriptorsState, Error>(
    asyncActions.updateTransferDescriptors,
    async (_, dispatch, getState) => {
        const {
            controls: { componentState, internalTransferDirection },
            cells: { from, to },
        } = getBudgetTransferMenuState(getState());

        let transferDescriptors: TransferDescriptorsState;
        if (componentState === ComponentState.ExternalIncomeTransfer) {
            transferDescriptors = [
                {
                    from: null,
                    to: to[0],
                    amount: 0,
                },
            ];
        } else if (componentState === ComponentState.ExternalOutcomeTransfer) {
            transferDescriptors = [
                {
                    from: from[0],
                    to: null,
                    amount: 0,
                },
            ];
        } else {
            switch (internalTransferDirection) {
                case InternalTransferDirection.OneToMany:
                    transferDescriptors = flatten(
                        to.map((toCell) => {
                            const monthsToUse =
                                from[0].budgetItem.id === toCell.budgetItem.id
                                    ? MonthUtils.Months.filter((month) => month !== from[0].month)
                                    : MonthUtils.Months;

                            return monthsToUse.map((month) => ({
                                from: from[0],
                                to: {
                                    budgetItem: toCell.budgetItem,
                                    month,
                                },
                                amount: 0,
                            }));
                        }),
                    );
                    break;
                case InternalTransferDirection.ManyToOne:
                    transferDescriptors = flatten(
                        from.map((fromCell) => {
                            const fromMonths = MonthUtils.Months.filter((month) => {
                                if (fromCell.budgetItem.id === to[0].budgetItem.id && to[0].month === month) {
                                    return false;
                                }

                                return fromCell.budgetItem.plannedFunds[month];
                            });

                            return fromMonths.map((month) => ({
                                from: {
                                    budgetItem: fromCell.budgetItem,
                                    month,
                                },
                                to: to[0],
                                amount: 0,
                            }));
                        }),
                    );
                    break;
                default:
                    transferDescriptors = [];
                    break;
            }
        }

        return transferDescriptors;
    },
);
