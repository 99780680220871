import { actionCreatorFactory } from 'typescript-fsa';
import { BriefScheme } from 'sber-marketing-types/frontend';
import { User } from '../types';

const actionCreator = actionCreatorFactory('USER');

const loadUser = actionCreator<User>('LOAD_USER');
const loadBudgetScheme = actionCreator<BriefScheme>('LOAD_BUDGET_SCHEME');

export { loadUser, loadBudgetScheme };
