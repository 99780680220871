import * as moment from 'moment';
import * as lodash from 'lodash';
import { AccountStatus } from 'sber-marketing-types/openid';
import { BriefScheme } from 'sber-marketing-types/frontend';

import { BudgetItem, DictionaryType, Funds, Month } from '@mrm/budget';

import {
    ColumnData,
    ColumnName,
    CustomCellType,
    CellValueType,
    AccessorParams,
    NumericCellValue,
    PLANNED_COLUMN_NAMES,
    CustomColumnColor,
    TOTAL_PLAN_QUARTER_COLUMN_NAMES,
} from '@store/budgetPlanning/types';
import { formatResponsibleName, makeNumberValue, makeDateValue } from './lib';
import { CellBackgroundColor, ActualValue } from './Table/LayerManager/LayerManager';

const MONTH_NAMES = makeMonthNames();
const MONTHS_IN_QUARTER = 3;

export const makeColumnList = (budgetSchema: BriefScheme): ColumnData[] => {
    const budgetSchemaFields = budgetSchema.blocks.flatMap((block) => block.fields);
    const budgetSchemaColumns: ColumnData[] = budgetSchemaFields.reduce((acc, field) => {
        const dictionaryType = field.properties.dictionaryType as any as DictionaryType;

        if (field.properties.isDictionary) {
            const dataColumn: ColumnData = {
                name: `dictionary_${field.properties.dictionaryType}-${field.id}`,
                title: field.properties.name,
                width: 230,
                valueType: CellValueType.String,
                customCellType: CustomCellType.Dropdown,
                metaData: {
                    dictionaryType,
                },
                accessor: (params) => getDictionaryValue(params.budgetItem, dictionaryType),
            };
            const codeColumn: ColumnData = {
                ...dataColumn,
                name: `dictionary-code_${field.properties.dictionaryType}-${field.id}`,
                title: `Код ${dataColumn.title}`,
                hiddenByDefault: true,
            };

            acc.push(dataColumn, codeColumn);
        } else {
            acc.push({
                name: field.properties.budgetField,
                title: field.properties.name,
                width: 200,
                valueType: CellValueType.String,
                customCellType: CustomCellType.Input,
                accessor: (params) => params.budgetItem[field.properties.budgetField as keyof BudgetItem],
            } as ColumnData);
        }

        return acc;
    }, []);

    return [
        {
            name: ColumnName.Id,
            title: 'ID плана',
            width: 135,
            valueType: CellValueType.Number,
            accessor: (params) => makeNumberValue(params.budgetItem.serialNumber),
        },
        {
            name: ColumnName.ActivityName,
            title: 'Активность',
            width: 200,
            valueType: CellValueType.String,
            customCellType: CustomCellType.Input,
            accessor: (params) => params.activityBudgets.find((item) => item.id == params.budgetItem.activity.id)?.name,
        },
        ...budgetSchemaColumns,
        {
            name: ColumnName.StartDate,
            title: 'Дата старта',
            width: 170,
            valueType: CellValueType.Date,
            customCellType: CustomCellType.Datepicker,
            accessor: (params) =>
                params.budgetItem.realizationStart ? makeDateValue(new Date(params.budgetItem.realizationStart)) : null,
        },
        {
            name: ColumnName.EndDate,
            title: 'Дата окончания',
            width: 170,
            valueType: CellValueType.Date,
            customCellType: CustomCellType.Datepicker,
            accessor: (params) =>
                params.budgetItem.realizationEnd ? makeDateValue(new Date(params.budgetItem.realizationEnd)) : null,
        },
        ...lodash.flatten(
            lodash.times(4, (quarter) => [
                ...lodash.flatten(
                    lodash.times(3, (quarterMonthIndex) => {
                        const monthIndex = quarter * 3 + quarterMonthIndex;

                        return [
                            {
                                name: PLANNED_COLUMN_NAMES[monthIndex],
                                title: `${MONTH_NAMES[monthIndex]} план, тыс. ₽`,
                                width: 160,
                                valueType: CellValueType.Currency,
                                customCellType: CustomCellType.Input,
                                metaData: {
                                    month: monthIndex + 1,
                                },
                                accessor: (params) =>
                                    makeBudgetValueByMonth(params.budgetItem.plannedFunds, monthIndex),
                            } as ColumnData,
                        ];
                    }),
                ),
                {
                    name: TOTAL_PLAN_QUARTER_COLUMN_NAMES[quarter],
                    title: `Итого План ${quarter + 1} квартал, тыс. руб.`,
                    width: 160,
                    customColumnColor: CustomColumnColor.CurrencySum,
                    valueType: CellValueType.Currency,
                    metaData: {
                        calculateActualValue: (getActualValue: (columnName: ColumnName) => ActualValue): ActualValue =>
                            calculateActualValueForQuarter(getActualValue, PLANNED_COLUMN_NAMES, quarter),
                        relatedColumns: getItemsByQuarter(PLANNED_COLUMN_NAMES, quarter),
                    },
                },
            ]),
        ),
        {
            name: ColumnName.TotalPlan,
            title: 'Итого план, тыс. ₽',
            width: 160,
            valueType: CellValueType.Currency,
            metaData: {
                calculateActualValue: (getActualValue: (columnName: ColumnName) => ActualValue): ActualValue => {
                    const actualValues = PLANNED_COLUMN_NAMES.map((item) => getActualValue(item));

                    const planValues = actualValues.map((item) => (item.value ? parseInt(item.value, 10) : 0));

                    const valueSum = planValues.reduce((acc, item) => acc + item, 0);

                    return {
                        value: valueSum.toString(),
                        color: makeColorByActualValues(actualValues),
                    };
                },
                relatedColumns: PLANNED_COLUMN_NAMES,
            },
        },
        {
            name: ColumnName.FactPreviousPeriod,
            title: 'Факт предыдущего периода',
            width: 160,
            valueType: CellValueType.Currency,
            customCellType: CustomCellType.Input,
            accessor: (params) => makeNumberValue(params.budgetItem.factPreviousPeriod),
        },
        {
            name: ColumnName.Comment,
            title: 'Примечания',
            width: 150,
            valueType: CellValueType.String,
            customCellType: CustomCellType.Input,
            accessor: (params) => params.budgetItem.comment || null,
        },
        {
            name: ColumnName.Author,
            title: 'Автор строки бюджета',
            width: 175,
            valueType: CellValueType.String,
            accessor: (params) => {
                const author = params.budgetItem.author;

                return `${lodash.get(author, 'secondName') || ''} ${lodash.get(author, 'firstName') || ''}`;
            },
        },
        {
            name: ColumnName.Tags,
            title: 'Теги',
            width: 170,
            valueType: CellValueType.String,
            accessor: (params) =>
                lodash.compact(params.budgetItem.tags?.map((tagId) => params.tags[tagId]?.title))?.join(', '),
        },
    ];
};

export const ColumnsList: ColumnData[] = [
    {
        name: ColumnName.Id,
        title: 'ID плана',
        width: 135,
        valueType: CellValueType.Number,
        accessor: (params) => makeNumberValue(params.budgetItem.serialNumber),
    },
    {
        name: ColumnName.ActivityName,
        title: 'Активность',
        width: 200,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Input,
        accessor: (params) => params.activityBudgets.find((item) => item.id == params.budgetItem.activity.id)?.name,
    },
    {
        name: ColumnName.Regionality,
        title: 'ЦА/ТБ (территория)',
        width: 180,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Regionality,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Regionality),
    },
    {
        name: ColumnName.RegionalityCode,
        title: 'Код ЦА/ТБ (территория)',
        width: 155,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Regionality,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Regionality),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.ActivityType,
        title: 'Тип проекта',
        width: 140,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.ActivityType,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.ActivityType),
    },
    {
        name: ColumnName.ActivityTypeCode,
        title: 'Код Тип проекта',
        width: 140,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.ActivityType,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.ActivityType),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Direction,
        title: 'Направление',
        width: 190,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Direction,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Direction),
    },
    {
        name: ColumnName.DirectionCode,
        title: 'Код Направление',
        width: 190,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Direction,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Direction),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.SapComment,
        title: 'Название проекта',
        width: 170,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Input,
        accessor: (params) => params.budgetItem.sapComment || null,
    },
    {
        name: ColumnName.Tool,
        title: 'Инструмент',
        width: 230,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Tool,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Tool),
    },
    {
        name: ColumnName.ToolCode,
        title: 'Код Инструмент',
        width: 230,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Tool,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Tool),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Block,
        title: 'Блок',
        width: 210,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Block,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Block),
    },
    {
        name: ColumnName.BlockCode,
        title: 'Код Блок',
        width: 210,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Block,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Block),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.CostCenter,
        title: 'ЦЗ',
        width: 235,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.CostCenter,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.CostCenter),
    },
    {
        name: ColumnName.CostCenterCode,
        title: 'Код ЦЗ',
        width: 235,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.CostCenter,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.CostCenter),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Division,
        title: 'МВЗ (Дивизион)',
        width: 230,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Division,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Division),
    },
    {
        name: ColumnName.DivisionCode,
        title: 'Код МВЗ (Дивизион)',
        width: 230,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Division,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Division),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Item,
        title: 'Статья',
        width: 120,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Item,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Item),
    },
    {
        name: ColumnName.Resource,
        title: 'Ресурс',
        width: 120,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Resource,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Resource),
    },
    {
        name: ColumnName.ResourceCode,
        title: 'Код Ресурс',
        width: 120,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Resource,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Resource),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Territory,
        title: 'Территория',
        width: 175,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Territory,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Territory),
    },
    {
        name: ColumnName.TerritoryCode,
        title: 'Код Территория',
        width: 120,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Territory,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Territory),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.LocationDriver,
        title: 'Драйвер аллокации (Бизнес-блок)',
        width: 175,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.LocationDriver,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.LocationDriver),
    },
    {
        name: ColumnName.LocationDriverCode,
        title: 'Код Драйвер аллокации (Бизнес-блок)',
        width: 175,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.LocationDriver,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.LocationDriver),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Segment,
        title: 'Сегмент',
        width: 170,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Segment,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Segment),
    },
    {
        name: ColumnName.SegmentCode,
        title: 'Код Сегмент',
        width: 170,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Segment,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Segment),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Product,
        title: 'Продукт',
        width: 170,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Product,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Product),
    },
    {
        name: ColumnName.ProductCode,
        title: 'Код Продукт',
        width: 170,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Product,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Product),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Channel,
        title: 'Канал',
        width: 220,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Channel,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Channel),
    },
    {
        name: ColumnName.ChannelCode,
        title: 'Код Канал',
        width: 220,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dictionaryType: DictionaryType.Channel,
        },
        accessor: (params) => getDictionaryValue(params.budgetItem, DictionaryType.Channel),
        hiddenByDefault: true,
    },
    {
        name: ColumnName.Responsible,
        title: 'Руководители проекта (ответственные от ДМиК/ДУД)',
        width: 390,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Dropdown,
        metaData: {
            dropdownItems: (params: Partial<AccessorParams>) =>
                params.users
                    .filter((user) => user.status === AccountStatus.ACTIVE)
                    .map((item) => ({
                        title: formatResponsibleName(item),
                        id: item.id.toString(),
                    })),
        },
        accessor: (params) =>
            params.budgetItem.responsibles ? params.budgetItem.responsibles.map((user) => user.id).join() : null,
    },
    {
        name: ColumnName.BusinessGoal,
        title: 'Бизнес-цель',
        width: 150,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Input,
        accessor: (params) => params.budgetItem.businessTarget,
    },
    {
        name: ColumnName.Customer,
        title: 'ФИО Заказчика',
        width: 290,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Input,
        accessor: (params) => params.budgetItem.customerName,
    },
    {
        name: ColumnName.StartDate,
        title: 'Дата старта',
        width: 170,
        valueType: CellValueType.Date,
        customCellType: CustomCellType.Datepicker,
        accessor: (params) =>
            params.budgetItem.realizationStart ? makeDateValue(new Date(params.budgetItem.realizationStart)) : null,
    },
    {
        name: ColumnName.EndDate,
        title: 'Дата окончания',
        width: 170,
        valueType: CellValueType.Date,
        customCellType: CustomCellType.Datepicker,
        accessor: (params) =>
            params.budgetItem.realizationEnd ? makeDateValue(new Date(params.budgetItem.realizationEnd)) : null,
    },
    ...lodash.flatten(
        lodash.times(4, (quarter) => [
            ...lodash.flatten(
                lodash.times(3, (quarterMonthIndex) => {
                    const monthIndex = quarter * 3 + quarterMonthIndex;

                    return [
                        {
                            name: PLANNED_COLUMN_NAMES[monthIndex],
                            title: `${MONTH_NAMES[monthIndex]} план, тыс. ₽`,
                            width: 160,
                            valueType: CellValueType.Currency,
                            customCellType: CustomCellType.Input,
                            metaData: {
                                month: monthIndex + 1,
                            },
                            accessor: (params) => makeBudgetValueByMonth(params.budgetItem.plannedFunds, monthIndex),
                        } as ColumnData,
                    ];
                }),
            ),
            {
                name: TOTAL_PLAN_QUARTER_COLUMN_NAMES[quarter],
                title: `Итого План ${quarter + 1} квартал, тыс. руб.`,
                width: 160,
                customColumnColor: CustomColumnColor.CurrencySum,
                valueType: CellValueType.Currency,
                metaData: {
                    calculateActualValue: (getActualValue: (columnName: ColumnName) => ActualValue): ActualValue =>
                        calculateActualValueForQuarter(getActualValue, PLANNED_COLUMN_NAMES, quarter),
                    relatedColumns: getItemsByQuarter(PLANNED_COLUMN_NAMES, quarter),
                },
            },
        ]),
    ),
    {
        name: ColumnName.TotalPlan,
        title: 'Итого план, тыс. ₽',
        width: 160,
        valueType: CellValueType.Currency,
        metaData: {
            calculateActualValue: (getActualValue: (columnName: ColumnName) => ActualValue): ActualValue => {
                const actualValues = PLANNED_COLUMN_NAMES.map((item) => getActualValue(item));

                const planValues = actualValues.map((item) => (item.value ? parseInt(item.value, 10) : 0));

                const valueSum = planValues.reduce((acc, item) => acc + item, 0);

                return {
                    value: valueSum.toString(),
                    color: makeColorByActualValues(actualValues),
                };
            },
            relatedColumns: PLANNED_COLUMN_NAMES,
        },
    },
    {
        name: ColumnName.FactPreviousPeriod,
        title: 'Факт предыдущего периода',
        width: 160,
        valueType: CellValueType.Currency,
        customCellType: CustomCellType.Input,
        accessor: (params) => makeNumberValue(params.budgetItem.factPreviousPeriod),
    },
    {
        name: ColumnName.Comment,
        title: 'Примечания',
        width: 150,
        valueType: CellValueType.String,
        customCellType: CustomCellType.Input,
        accessor: (params) => params.budgetItem.comment || null,
    },
    {
        name: ColumnName.Author,
        title: 'Автор строки бюджета',
        width: 175,
        valueType: CellValueType.String,
        accessor: (params) => {
            const author = params.budgetItem.author;

            return `${lodash.get(author, 'secondName') || ''} ${lodash.get(author, 'firstName') || ''}`;
        },
    },
    {
        name: ColumnName.Tags,
        title: 'Теги',
        width: 170,
        valueType: CellValueType.String,
        accessor: (params) =>
            lodash.compact(params.budgetItem.tags?.map((tagId) => params.tags[tagId]?.title))?.join(', '),
    },
];

function calculateActualValueForQuarter(
    getActualValue: (columnName: ColumnName) => ActualValue,
    columns: ColumnName[],
    quarter: number,
): ActualValue {
    const actualValues = getItemsByQuarter(columns, quarter).map((item) => getActualValue(item));
    const values = actualValues.map((item) => (item.value ? parseFloat(item.value) : 0));
    const sum = values.reduce((acc, item) => acc + item, 0);

    return {
        value: sum.toString(),
        color: makeColorByActualValues(actualValues),
    };
}

function makeMonthNames(): string[] {
    moment.locale('ru');

    return moment.months().map((item) => lodash.capitalize(item));
}

function getDictionaryValue(budgetItem: BudgetItem, dictionaryType: DictionaryType): string {
    return budgetItem.dictionary && budgetItem.dictionary[dictionaryType]
        ? budgetItem.dictionary[dictionaryType].id
        : null;
}

function makeBudgetValueByMonth(funds: Funds, monthIndex: number): NumericCellValue {
    const month = getMonthByIndex(monthIndex);

    return makeNumberValue(funds[month] || null);
}

function makeColorByActualValues(actualValues: ActualValue[]): CellBackgroundColor {
    let color: CellBackgroundColor;

    const hasChanges = actualValues.some((item) => item.color == CellBackgroundColor.UnsavedChange);

    if (hasChanges) {
        color = CellBackgroundColor.UnsavedChange;
    }

    return color;
}

function getItemsByQuarter<T>(items: T[], quarterIndex: number): T[] {
    return items.slice(quarterIndex * MONTHS_IN_QUARTER, quarterIndex * MONTHS_IN_QUARTER + MONTHS_IN_QUARTER);
}

function getMonthByIndex(monthIndex: number): Month {
    const months = [
        Month.Jan,
        Month.Feb,
        Month.Mar,
        Month.Apr,
        Month.May,
        Month.Jun,
        Month.Jul,
        Month.Aug,
        Month.Sept,
        Month.Oct,
        Month.Nov,
        Month.Dec,
    ];

    return months[monthIndex];
}
