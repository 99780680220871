import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { BriefScheme } from 'sber-marketing-types/frontend';
import { User, UserState } from '../types';
import * as actions from '../actions';

const initialState: UserState = {
    attributes: null,
    userRoles: null,
    subordinates: [],
    permissions: [],
    userOrganizationBudgetScheme: null,
};

export const userReducer = reducerWithInitialState(initialState)
    .case(actions.loadUser, load)
    .case(actions.loadBudgetScheme, loadBudgetScheme);

function load(state: UserState, payload: User) {
    return { ...state, ...payload };
}

function loadBudgetScheme(state: UserState, userOrganizationBudgetScheme: BriefScheme) {
    return { ...state, userOrganizationBudgetScheme };
}
