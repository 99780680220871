import { bindThunkAction } from 'typescript-fsa-redux-thunk';
import { FilterItem } from 'sber-marketing-ui';

import { StoreState } from '@store';
import { getBudgetTableState, resetFilteredData } from '@store/upd_budgetPage/budgetTable';

import { BudgetItemApi } from '@api';

import * as actions from './actions/sync';
import * as asyncActions from './actions/async';

import {
    LoadFiltersPayload,
    LoadDictionaryFiltersPayload,
    SetDictionaryItemsPayload,
    SetDictionarySelectedItemsPayload,
} from './types';

import { getBudgetTableFiltersState } from './selectors';

export const loadFilters = bindThunkAction<StoreState, LoadFiltersPayload, void, Error>(
    asyncActions.loadFilters,
    async (payload, dispatch, getState) => {
        const { field } = payload;

        const state = getState();
        const { activeBudgetId } = getBudgetTableState(state);

        const { items } = getBudgetTableFiltersState(state);

        if (!items[field]) {
            const items: FilterItem[] = (
                await BudgetItemApi.getBudgetItemFilters({
                    budgetId: activeBudgetId,
                    column: field,
                })
            ).map((item) => {
                if (!item) {
                    return {
                        id: null,
                        title: 'Не задано',
                    };
                }

                return {
                    id: item,
                    title: item,
                };
            });

            dispatch(
                actions.setItems({
                    [field]: items,
                }),
            );
        }
    },
);

export const loadDictionaryFilters = bindThunkAction<StoreState, LoadDictionaryFiltersPayload, void, Error>(
    asyncActions.loadDictionaryFilters,
    async (payload, dispatch, getState) => {
        const { dictionaryType } = payload;

        const state = getState();
        const {
            activeBudgetId,
            data: { dictionaries },
        } = getBudgetTableState(state);

        const { items } = getBudgetTableFiltersState(state);

        if (!items.dictionary[dictionaryType]) {
            const items: FilterItem[] = (
                await BudgetItemApi.getBudgetItemFilters({
                    budgetId: activeBudgetId,
                    column: `dictionary.${dictionaryType}`,
                })
            ).map((item) => {
                if (!item) {
                    return {
                        id: null,
                        title: 'Не задано',
                    };
                }

                return {
                    id: item,
                    title: dictionaries.byId[item]?.value,
                };
            });

            dispatch(
                setDictionaryItems({
                    dictionaryType,
                    items,
                }),
            );
            dispatch(
                setDictionarySelectedItems({
                    dictionaryType,
                    selectedItems: [],
                }),
            );
        }
    },
);

export const setDictionaryItems = bindThunkAction<StoreState, SetDictionaryItemsPayload, void, Error>(
    asyncActions.setDictionaryItems,
    async (payload, dispatch, getState) => {
        dispatch(
            actions.setItems({
                dictionary: {
                    ...getBudgetTableFiltersState(getState()).items.dictionary,
                    [payload.dictionaryType]: payload.items,
                },
            }),
        );
    },
);

export const setDictionarySelectedItems = bindThunkAction<StoreState, SetDictionarySelectedItemsPayload, void, Error>(
    asyncActions.setDictionarySelectedItems,
    async (payload, dispatch, getState) => {
        const updDictionarySelectedItems = {
            ...getBudgetTableFiltersState(getState()).selectedItems.dictionary,
            [payload.dictionaryType]: {
                id: payload.selectedItems,
            },
        };

        if (!payload.selectedItems.length) {
            delete updDictionarySelectedItems[payload.dictionaryType];
        }

        dispatch(
            actions.setSelectedItems({
                dictionary: updDictionarySelectedItems,
            }),
        );
    },
);

export const resetFilters = bindThunkAction<StoreState, null, void, Error>(
    asyncActions.resetFilters,
    async (_, dispatch, getState) => {
        const filteredActivityBudgetIds = [
            ...getBudgetTableFiltersState(getState()).filteredData.activityBudgets.values(),
        ];

        dispatch(actions.resetSelectedFilters());
        dispatch(resetFilteredData(filteredActivityBudgetIds));
    },
);
