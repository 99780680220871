import { createSelector } from 'reselect';
import { BudgetItem } from '@mrm/budget';
import { DictionaryType } from '@mrm/dictionary';

import { StoreState } from '@store';

import { BudgetTableFiltersState as State } from './types';

export const getBudgetTableFiltersState = (state: StoreState): State => state.upd_budgetPage.budgetTableFilters;

export const filtersWereWereSet = createSelector(
    (state: StoreState) => getBudgetTableFiltersState(state).appliedFilters,
    (selectedFilters) =>
        Object.keys(selectedFilters).some((key: keyof BudgetItem) => {
            if (key === 'dictionary') {
                return Object.keys(selectedFilters.dictionary).some(
                    (dictionaryKey: DictionaryType) => selectedFilters.dictionary[dictionaryKey]?.id?.length,
                );
            }

            return selectedFilters[key].length;
        }),
);

export const filtersArePending = createSelector(
    (state: StoreState) => getBudgetTableFiltersState(state).selectedItems,
    (selectedFilters) =>
        Object.keys(selectedFilters).some((key: keyof BudgetItem) => {
            if (key === 'dictionary') {
                return Object.keys(selectedFilters.dictionary).some(
                    (dictionaryKey: DictionaryType) => selectedFilters.dictionary[dictionaryKey]?.id?.length,
                );
            }

            return selectedFilters[key].length;
        }),
);
