import { Money, Captions } from './Money';

import { CommonFormatter } from './CommonFormatter';

interface Params {
    forceSign?: boolean;
    hideCaption?: boolean;
    isCopecksRequired?: boolean;
    ommitZeroPadding?: boolean;
}

interface FormatParams {
    decimal: string;
    delimeter: string;
    fraction?: string;
    sign?: string;
    caption?: string;
}

export class MoneyFormatter {
    public static toRoubles(money: Money, params?: Params): string {
        const sign = this.getSign(money, params);
        const caption = params?.hideCaption ? '' : Captions.Roubles;

        const roubles = CommonFormatter.splitNumberByScales(money.getRoubles());
        const copecks = params?.isCopecksRequired
            ? this.strictFormatFraction(money.getCopecks() % 100.0, 2, params?.ommitZeroPadding)
            : this.lazyFormatFraction(money.getCopecks() % 100.0, 2, params?.ommitZeroPadding);

        return this.format({
            decimal: roubles,
            delimeter: '.',
            fraction: copecks,
            sign,
            caption,
        });
    }

    public static toThousands(money: Money, params?: Params): string {
        const sign = this.getSign(money, params);
        const caption = params?.hideCaption ? '' : Captions.Thousands;

        const thousands = CommonFormatter.splitNumberByScales(money.getRoundedThousands());
        const roubles = this.lazyFormatFraction(money.getRoundedRoubles() % 1000.0, 3, params?.ommitZeroPadding);

        return this.format({
            decimal: thousands,
            delimeter: ',',
            fraction: roubles,
            sign,
            caption,
        });
    }

    public static toMostAppropriate(money: Money, params?: Params): string {
        const thousands = money.getThousands();

        return thousands ? this.toThousands(money, params) : this.toRoubles(money, params);
    }

    public static pairToRoubles(m1: Money, m2: Money, p1?: Params, p2?: Params): string {
        return `${this.toRoubles(m1, p1)} ⟶ ${this.toRoubles(m2, p1 || p2)}`;
    }

    public static pairToThousands(m1: Money, m2: Money, p1?: Params, p2?: Params): string {
        return `${this.toThousands(m1, p1)} ⟶ ${this.toThousands(m2, p1 || p2)}`;
    }

    private static format(params: FormatParams): string {
        const { decimal, delimeter, fraction, sign, caption } = params;

        const fractionPart = fraction ? `${delimeter}${fraction}` : '';
        const captionPart = caption ? ` ${caption}` : '';

        return `${sign}${decimal}${fractionPart}${captionPart}`;
    }

    private static getSign(money: Money, params?: Params): string {
        const plus = params?.forceSign ? '+' : '';
        const minus = '-';

        const sign = money.getSign();

        return sign < 0 ? minus : plus;
    }

    private static lazyFormatFraction(value: number, nDigits: number, ommitZeroPadding: boolean): string {
        return value ? this.formatFraction(value, nDigits, ommitZeroPadding) : '';
    }

    private static strictFormatFraction(value: number, nDigits: number, ommitZeroPadding: boolean): string {
        return this.formatFraction(value, nDigits, ommitZeroPadding);
    }

    private static formatFraction(value: number, nDigits: number, ommitZeroPadding: boolean): string {
        const result = String(value).padStart(nDigits, '0');

        return ommitZeroPadding ? result.match(/(.*?)0*$/)?.[1] : result;
    }
}
