/* tslint:disable:max-file-line-count */
import { combineReducers } from 'redux';
import { Success } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { DictionaryType } from '@mrm/dictionary';

import { MultiReferenceDictionaryApi } from '@api';

import {
    ACTIVITY_FIELDS_COLUMN_NAMES,
    ACTIVITY_MONTH_BALANCE_COLUMN_NAMES,
    // ACTIVITY_QUARTER_BALANCE_COLUMN_NAMES,
    BudgetExecutionPageState,
    CellValueType,
    ChangeCellValueParams,
    ChangeList,
    ColumnName,
    ColumnsVisiblityFilter,
    ColumnsWidth,
    CORRECTION_FILTER_TYPE,
    CorrectionPopup,
    CustomCellType,
    Filters,
    GroupedCorrections,
    LineCorrectionTypes,
    LoadFiltersPaylaod,
    OrderType,
    PageData,
    PageState,
    SetFiltersLoadingStatusPayload,
    SortingMode,
    UnsavedChange,
    UpdateLinesDataParams,
    ResetFilterPayload,
    AppliedFiltersNames,
    ActivityReferenceMenuVisibility,
    CreativeReferenceMenuVisibility,
    SetPreviouslyLoadedFilterPayload,
    SetPreviouslyLoadedFiltersPayload,
    TableLinesCellsParams,
    PageFilters,
    TableLine,
    DropdownOptions,
    ColumnsWithSameData,
    BudgetColumns,
    ColumnData,
} from './types';

import * as actions from './actions';
import { makeColumnList } from '../../modules/budget/BudgetPage/BudgetExecution/ColumnsConfig';
import { Utils } from '@common/Utils';
import { LinkedList } from './lib/LinkedList';

import { LoadingStatus } from '@store/commonTypes';

import { budgetTransferMenuReducer } from './budgetTransferMenu';
import { activityReferenceMenuReducer } from './activityReferenceMenu';
import { creativeReferenceMenuReducer } from './creativeReferenceMenu';
import { lineModalReducer } from './lineModal';
import { transferBudgetItemsToPlanningMenuReducer } from './transferBudgetItemsToPlanningMenu';
import { miscBudgetItemsReducer } from './miscBudgetItems';
import { importFMPTableMenuReducer } from './importFMPTableMenu';

import { ActivityBudget, BudgetItem, CorrectionType } from '@mrm/budget';
import { updateAppliedFiltersNamesUseAllFilters, updateColumnFilterLoadingStatus } from './utils';

const sortingModeInitialState = {
    columnName: ColumnName.Id,
    order: OrderType.Asc,
};

function makeColumnsWidthInitialState(columns: ColumnData[]) {
    return columns.reduce((acc, item) => ({ ...acc, [item.name]: item.width }), {});
}

function makeColumnsVisibilityFilterInitialState(columns: ColumnData[]) {
    const allItemsAreHiddenByDefault = columns.every((column) => column.hiddenByDefault);
    const allItemsAreVisibleByDefault = columns.every((column) => !column.hiddenByDefault);

    return columns.reduce((acc, item) => {
        acc[item.name] = allItemsAreHiddenByDefault || allItemsAreVisibleByDefault ? false : !item.hiddenByDefault;

        return acc;
    }, {});
}

export function makeCorrectionsToDisplayInitialState() {
    return {
        [CORRECTION_FILTER_TYPE.ACTIVITY_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.BUDGET_ITEM_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.PLAN_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.RESERVE_CORRECTION]: false,
        [CORRECTION_FILTER_TYPE.NO_CORRECTIONS]: false,
    };
}

class Reducer {
    public static makeInitialState(): PageState {
        return {
            pageData: {
                activityBudgets: [],
                budget: null,
                budgetItems: [],
                budgetItemsToIgnoreFilters: [],
                userDictionaries: { byId: {}, byType: {} },
                allDictionaries: { byId: {}, byType: {} },
                users: [],
                allUsers: [],
                corrections: {
                    activityCorrections: {},
                    budgetItemCorrections: {},
                    planCorrections: {},
                    reserveCorrections: {},
                    factCorrections: {},
                    incomeExternalPlanCorrections: {},
                    outcomeExternalPlanCorrections: {},
                },
                budgetSchema: null,
                budgetColumns: {
                    all: [],
                    byName: {},
                    dicitonaryColumns: [],
                    calculatableColumns: [],
                    dictionaryDropdownColumns: [],
                    byValueType: {
                        [CellValueType.Currency]: [],
                        [CellValueType.Date]: [],
                        [CellValueType.Number]: [],
                        [CellValueType.String]: [],
                    },
                    byCustomCellType: {
                        [CustomCellType.Datepicker]: [],
                        [CustomCellType.Dropdown]: [],
                        [CustomCellType.Input]: [],
                        [CustomCellType.Selectable]: [],
                    },
                },
            },
            computedData: {
                pageBudgetItems: [],
                lineIdsWithActualChanges: [],
                tableLinesCellsParams: {},
                tableLines: [],
                dropdownOptions: {},
                emptyActivityBudgets: [],
            },
            pageFilters: {
                columnsVisiblityFilter: {},
                sortingMode: sortingModeInitialState,
                filters: {},
                appliedFiltersNames: [],
                validationStatus: false,
                showOnlyLinesWithoutPlan: false,
                showOnlyLinesWithNegativeBalance: false,
                useLinesWithoutPlanInSorting: false,
                correctionsToDisplay: makeCorrectionsToDisplayInitialState(),
                showOnlyLinesWithPlanBudget: false,
                showOnlyLinesWithNegativePlanFactDiff: false,
                showEmptyActivities: false,
            },
            activityReferenceMenuVisibility: {
                visibility: false,
            },
            creativeReferenceMenuVisibility: {
                visibility: false,
            },
            columnsWidth: {},
            resizingColumnName: null,
            hoveredLineId: null,
            hoveredColumnName: null,
            unsavedChanges: {},
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
            fixedColumnsNames: [],
            correctionPopup: {
                show: false,
            },
            columnFilters: {
                filters: {},
                loadingStatus: {},
            },
            loadingFiltersCount: 0,
            previouslyLoadedFilters: null,
            showTagsHaveChangedMarker: false,
            multiReferenceDictionaryApi: null,
            preventUserConfigUpdate: false,
        };
    }

    public static loadPageData(state: PageState, payload: Partial<PageData>): PageState {
        const result: PageState = {
            ...state,
            pageData: {
                ...state.pageData,
                ...payload,
            },
        };

        if (payload.budgetColumns) {
            const columnsWidth = makeColumnsWidthInitialState(payload.budgetColumns.all);
            const columnsVisiblityFilter = makeColumnsVisibilityFilterInitialState(payload.budgetColumns.all);

            const columnFilters = {
                filters: payload.budgetColumns.all.reduce((acc, column) => {
                    acc[column.name] = {};

                    return acc;
                }, {} as PageState['columnFilters']['filters']),
                loadingStatus: payload.budgetColumns.all.reduce((acc, column) => {
                    acc[column.name] = LoadingStatus.NOT_LOADED;

                    return acc;
                }, {} as PageState['columnFilters']['loadingStatus']),
            };

            result.columnsWidth = columnsWidth;
            result.columnFilters = columnFilters;
            result.pageFilters = {
                ...result.pageFilters,
                columnsVisiblityFilter,
            };
        }

        return result;
    }

    public static loadBudgetItems(state: PageState, budgetItems: BudgetItem[]): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                budgetItems,
            },
        };
    }

    public static loadActivityBudgets(state: PageState, activityBudgets: ActivityBudget[]): PageState {
        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
            },
        };
    }

    public static updateLinesData(state: PageState, payload: UpdateLinesDataParams): PageState {
        const activityBudgets = lodash.uniqBy(
            [...payload.activityBudgets, ...state.pageData.activityBudgets],
            (activityBudget) => activityBudget.id,
        );

        const budgetItems = lodash.uniqBy(
            [...payload.budgetItems, ...state.pageData.budgetItems],
            (budgetItem) => budgetItem.id,
        );

        const budgetItemsToIgnoreFilters = lodash.uniqBy(
            [...payload.budgetItemsToIgnoreFilters, ...state.pageData.budgetItemsToIgnoreFilters],
            (budgetItem) => budgetItem.id,
        );

        const corrections = {
            activityCorrections: mergeCorrections(
                state.pageData.corrections.activityCorrections,
                payload.corrections.activityCorrections,
            ),
            budgetItemCorrections: mergeCorrections(
                state.pageData.corrections.budgetItemCorrections,
                payload.corrections.budgetItemCorrections,
            ),
            planCorrections: mergeCorrections(
                state.pageData.corrections.planCorrections,
                payload.corrections.planCorrections,
            ),
            factCorrections: mergeCorrections(
                state.pageData.corrections.factCorrections,
                payload.corrections.factCorrections,
            ),
            reserveCorrections: mergeCorrections(
                state.pageData.corrections.reserveCorrections,
                payload.corrections.reserveCorrections,
            ),
            incomeExternalPlanCorrections: mergeCorrections(
                state.pageData.corrections.incomeExternalPlanCorrections,
                payload.corrections.incomeExternalPlanCorrections,
            ),
            outcomeExternalPlanCorrections: mergeCorrections(
                state.pageData.corrections.outcomeExternalPlanCorrections,
                payload.corrections.outcomeExternalPlanCorrections,
            ),
        };

        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
                budgetItems,
                budgetItemsToIgnoreFilters,
                corrections,
            },
        };
    }

    public static initUnsavedChanges(state: PageState, payload: ChangeList): PageState {
        return { ...state, unsavedChanges: { ...state.unsavedChanges, ...payload } };
    }

    public static setColumnsVisiblityFilter(state: PageState, payload: ColumnsVisiblityFilter): PageState {
        const columnsVisiblityFilter = lodash.keys(state.pageFilters.columnsVisiblityFilter).reduce(
            (acc, columnName) => ({
                ...acc,
                ...{
                    [columnName]: !lodash.isNil(payload[columnName])
                        ? payload[columnName]
                        : state.pageFilters.columnsVisiblityFilter[columnName],
                },
            }),
            {},
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                columnsVisiblityFilter,
            },
        };
    }

    public static setColumnsWidth(state: PageState, payload: ColumnsWidth): PageState {
        const updatedColumnsWidth = { ...state.columnsWidth, ...payload };

        return { ...state, columnsWidth: updatedColumnsWidth };
    }

    public static setResizingColumnName(state: PageState, payload: ColumnName): PageState {
        return { ...state, resizingColumnName: payload };
    }

    public static setHoveredColumnName(state: PageState, payload: ColumnName): PageState {
        return { ...state, hoveredColumnName: payload };
    }

    public static setSortingMode(state: PageState, payload: SortingMode): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, sortingMode: payload } };
    }

    public static setFilters(state: PageState, payload: Filters): PageState {
        const filters = {
            ...state.columnFilters.filters,
            ...payload,
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        // const loadingStatus = updateColumnFilterLoadingStatus({
        //     filtersGroup: {
        //         prev: state.columnFilters.filters,
        //         current: filters,
        //     },
        //     appliedFiltersNamesGroup: {
        //         prev: state.pageFilters.appliedFiltersNames,
        //         current: appliedFiltersNames,
        //     },
        //     columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
        // });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
        };
    }

    public static setAppliedFiltersNames(state: PageState, payload: AppliedFiltersNames): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: payload,
            },
        };
    }

    public static resetFilter(state: PageState, payload: ResetFilterPayload): PageState {
        const { filterName } = payload;

        const filters = {
            ...state.columnFilters.filters,
            [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, filterName) => {
                return { ...filter, [filterName]: false };
            }, {}),
        };

        const appliedFiltersNames = updateAppliedFiltersNamesUseAllFilters({
            filters,
            appliedFiltersNames: state.pageFilters.appliedFiltersNames,
        });

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames,
            },
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [filterName]: lodash.keys(state.columnFilters.filters[filterName]).reduce((filter, key) => {
                        return {
                            ...filter,
                            [key]: false,
                        };
                    }, {}),
                },
                loadingStatus: updateColumnFilterLoadingStatus({
                    filtersGroup: {
                        prev: state.columnFilters.filters,
                        current: filters,
                    },
                    appliedFiltersNamesGroup: {
                        prev: state.pageFilters.appliedFiltersNames,
                        current: appliedFiltersNames,
                    },
                    columnFiltersLoadingStatus: state.columnFilters.loadingStatus,
                }),
            },
        };
    }

    public static resetFilters(state: PageState): PageState {
        const cleanUpdatedFilters = lodash.cloneDeep(state.columnFilters.filters);
        const cleanUpdatedFiltersLoadingStatus = lodash.cloneDeep(state.columnFilters.loadingStatus);

        lodash.forEach(cleanUpdatedFilters, (columnFilters, columnName) => {
            lodash.forEach(columnFilters, (value, title) => {
                cleanUpdatedFilters[columnName][title] = false;
            });
        });

        lodash.forEach(
            cleanUpdatedFiltersLoadingStatus,
            (columnFilters, columnName) => (cleanUpdatedFiltersLoadingStatus[columnName] = LoadingStatus.NOT_LOADED),
        );

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                appliedFiltersNames: [],
                correctionsToDisplay: makeCorrectionsToDisplayInitialState(),
                showOnlyLinesWithoutPlan: false,
                showOnlyLinesWithNegativeBalance: false,
            },
            columnFilters: {
                filters: cleanUpdatedFilters,
                loadingStatus: cleanUpdatedFiltersLoadingStatus,
            },
        };
    }

    public static resetViewSettings(state: PageState): PageState {
        const columnsInitialState = makeColumnList(state.pageData.budgetSchema);
        const columnsVisibilityFilterInitialState = makeColumnsVisibilityFilterInitialState(columnsInitialState);

        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                sortingMode: sortingModeInitialState,
                columnsVisiblityFilter: columnsVisibilityFilterInitialState,
            },
            fixedColumnsNames: [],
            columnsWidth: makeColumnsWidthInitialState(columnsInitialState),
        };
    }

    public static setValidationStatus(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                validationStatus: payload,
            },
        };
    }

    public static toggleColumnFix(state: PageState, payload: ColumnName): PageState {
        return {
            ...state,
            fixedColumnsNames: lodash.xor(state.fixedColumnsNames, [payload]),
        };
    }

    public static setFixedColumnsNames(state: PageState, payload: ColumnName[]): PageState {
        return {
            ...state,
            fixedColumnsNames: payload,
        };
    }

    public static setActivityReferenceMenuVisibility(
        state: PageState,
        payload: ActivityReferenceMenuVisibility,
    ): PageState {
        return {
            ...state,
            activityReferenceMenuVisibility: payload,
        };
    }

    public static setCreativeReferenceMenuVisibility(
        state: PageState,
        payload: CreativeReferenceMenuVisibility,
    ): PageState {
        return {
            ...state,
            creativeReferenceMenuVisibility: payload,
        };
    }

    public static changeCellValueDone(
        state: PageState,
        payload: Success<ChangeCellValueParams, ChangeCellValueParams>,
    ): PageState {
        const { change, line, generateSameValueChange, tags } = payload.result;
        const { pageData, unsavedChanges, changesHistory, currentChangePosition, multiReferenceDictionaryApi } = state;
        const { activityBudgets, budgetItems, budgetItemsToIgnoreFilters, userDictionaries, users } = pageData;

        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];
        const budgetItem = budgetItemsToUse.find((item) => item.id == change.budgetItemId);
        const column = pageData.budgetColumns.byName[change.columnName];
        const dictionaryType = lodash.get(column, 'metaData.dictionaryType') as DictionaryType;

        const activityBudgetFieldChanged = lodash.includes(ACTIVITY_FIELDS_COLUMN_NAMES, column.name);

        const newChange = validateNewChange(pageData.budgetColumns, change);
        let changes: UnsavedChange[] = [];

        if (dictionaryType) {
            const lineDictionaryValue: Partial<Record<DictionaryType, string>> = {};
            state.pageData.budgetColumns.dicitonaryColumns.forEach((dictionaryColumn) => {
                if (!dictionaryColumn.name.startsWith('dicitonary-code')) {
                    const columnChange = unsavedChanges[line.id]?.find(
                        (change) => change.columnName === dictionaryColumn.name,
                    );
                    const valueToUse = (
                        columnChange ? columnChange.value : line.fields[dictionaryColumn.name]
                    ) as string;

                    if (valueToUse && userDictionaries.byId[valueToUse]) {
                        lineDictionaryValue[dictionaryColumn.metaData.dictionaryType] =
                            userDictionaries.byId[valueToUse].id;
                    }
                }
            });

            const updatedValue = multiReferenceDictionaryApi.performDictionaryUpdate(
                Object.values(lineDictionaryValue),
                dictionaryType,
                (newChange.value || null) as string,
            );

            const keysToCheck = lodash.uniq([
                ...Object.keys(lineDictionaryValue),
                ...Object.keys(updatedValue),
            ]) as DictionaryType[];

            keysToCheck.forEach((updatedValueKey: DictionaryType) => {
                const changeColumn = state.pageData.budgetColumns.dicitonaryColumns.find(
                    (column) => column.metaData.dictionaryType === updatedValueKey,
                );

                if (changeColumn) {
                    const value = updatedValue[updatedValueKey]?.id || null;
                    const originalValue = changeColumn.accessor({
                        budgetItem,
                        activityBudgets,
                        users,
                        tags,
                    }) as string;

                    let shouldAddChange;
                    if (
                        updatedValueKey === DictionaryType.Regionality &&
                        change?.columnName !== ColumnName.Regionality &&
                        value &&
                        originalValue
                    ) {
                        shouldAddChange = false;
                    } else if (change.columnName === changeColumn.name) {
                        shouldAddChange = true;
                    } else {
                        const valueAppearedOrDisappearedAfterMutation =
                            updatedValue[changeColumn.metaData.dictionaryType]?.id !== originalValue;

                        shouldAddChange = valueAppearedOrDisappearedAfterMutation;
                    }

                    if (shouldAddChange) {
                        const columnsToAddChange: ColumnName[] = ColumnsWithSameData[changeColumn.name] || [
                            changeColumn.name,
                        ];

                        columnsToAddChange.forEach((changeColumn) => {
                            changes.push({
                                budgetItemId: newChange.budgetItemId,
                                columnName: changeColumn,
                                value,
                                originalValue,
                            });
                        });
                    }
                }
            });
        } else {
            changes = [newChange];

            if (activityBudgetFieldChanged) {
                const activityNameChange = changes[0];

                const activityId = budgetItem.activity.id;
                const activityBudgetItems = budgetItemsToUse.filter((item) => item.activity.id == activityId);

                activityBudgetItems.forEach((budgetItem) => {
                    if (activityNameChange.budgetItemId !== budgetItem.id) {
                        changes.push({ ...activityNameChange, budgetItemId: budgetItem.id });
                    }
                });
            }
        }

        if (currentChangePosition < changesHistory.length) {
            changesHistory.remove(currentChangePosition + 1);
        }

        changesHistory.add(changes);

        const updatedChangesList = lodash.clone(unsavedChanges);

        applyChangesToChangeList(updatedChangesList, changes, pageData.budgetColumns, generateSameValueChange);

        return {
            ...state,
            unsavedChanges: updatedChangesList,
            currentChangePosition: changesHistory.length,
        };
    }

    public static undoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition, pageData } = state;

        if (currentChangePosition < 1) {
            return state;
        }

        const newChangePosition = currentChangePosition - 1;

        const changesToUndo = changesHistory.getValueAtPosition(currentChangePosition);
        const changesToRestore = findLastChangesAtSameCells(newChangePosition, changesToUndo);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = removeChangesFromChangeList(updatedChangeList, changesToUndo);
        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore, pageData.budgetColumns);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };

        function findLastChangesAtSameCells(startPosition: number, changes: UnsavedChange[]): UnsavedChange[] {
            if (startPosition < 1) {
                return [];
            }

            let currentPosition = startPosition;

            let currentChanges: UnsavedChange[] = changesHistory.getValueAtPosition(currentPosition);

            while (!changesAreAtSameCells(currentChanges, changes) && currentPosition > 0) {
                currentPosition--;

                if (currentPosition > 0) {
                    currentChanges = changesHistory.getValueAtPosition(currentPosition);
                }
            }

            return currentPosition > 0 ? currentChanges : [];

            function changesAreAtSameCells(changesA: UnsavedChange[], changesB: UnsavedChange[]): boolean {
                return changesA.every((changeA) =>
                    changesB.some(
                        (changeB) =>
                            changeA.budgetItemId == changeB.budgetItemId && changeA.columnName == changeB.columnName,
                    ),
                );
            }
        }
    }

    public static redoUnsavedChanges(state: PageState): PageState {
        const { unsavedChanges, changesHistory, currentChangePosition, pageData } = state;

        const newChangePosition = currentChangePosition + 1;

        if (newChangePosition > changesHistory.length) {
            return state;
        }

        const changesToRestore = changesHistory.getValueAtPosition(newChangePosition);

        let updatedChangeList = lodash.clone(unsavedChanges);

        updatedChangeList = applyChangesToChangeList(updatedChangeList, changesToRestore, pageData.budgetColumns);

        return {
            ...state,
            unsavedChanges: updatedChangeList,
            currentChangePosition: newChangePosition,
        };
    }

    public static clearUnsavedChanges(state: PageState): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;

        const lineIds = [...budgetItems, ...budgetItemsToIgnoreFilters].map((item) => item.id);

        const initialChangesList = {};

        lineIds.forEach((lineId) => {
            initialChangesList[lineId] = [];
        });

        return { ...state, unsavedChanges: initialChangesList };
    }

    public static clearUnsavedChangesByLines(state: PageState, payload: string[]): PageState {
        const { budgetItems, budgetItemsToIgnoreFilters } = state.pageData;
        const budgetItemsToUse = [...budgetItems, ...budgetItemsToIgnoreFilters];

        const updatedChangeList = lodash.clone(state.unsavedChanges);

        const lineIds: string[] = [];

        payload.forEach((lineId) => {
            const budgetItem = budgetItemsToUse.find((item) => item.id == lineId);

            const activityLinesIds = budgetItemsToUse
                .filter((item) => updatedChangeList[item.id] && item.activity.id == budgetItem.activity.id)
                .map((item) => item.id);

            lineIds.push(...activityLinesIds);
        });

        lineIds.forEach((lineId) => {
            const updatedLineChanges = updatedChangeList[lineId].filter(
                (change) =>
                    !lodash.includes(
                        [
                            ...ACTIVITY_FIELDS_COLUMN_NAMES,
                            ...ACTIVITY_MONTH_BALANCE_COLUMN_NAMES,
                            // ...ACTIVITY_QUARTER_BALANCE_COLUMN_NAMES,
                        ],
                        change.columnName,
                    ),
            );

            updatedChangeList[lineId] = updatedLineChanges;
        });

        payload.forEach((lineId) => {
            updatedChangeList[lineId] = [];
        });

        return { ...state, unsavedChanges: updatedChangeList };
    }

    public static resetChangesHistory(state: PageState): PageState {
        return {
            ...state,
            changesHistory: new LinkedList<UnsavedChange[]>(),
            currentChangePosition: 0,
        };
    }

    public static setShowOnlyLinesWithoutPlan(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                showOnlyLinesWithoutPlan: payload,
            },
        };
    }

    public static setShowOnlyLinesWithNegativeBalance(state: PageState, payload: boolean): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                showOnlyLinesWithNegativeBalance: payload,
            },
        };
    }

    public static setUseLinesWithoutPlanInSorting(state: PageState, useLinesWithoutPlanInSorting: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, useLinesWithoutPlanInSorting },
        };
    }

    public static setShowOnlyLinesWithPlanBudget(state: PageState, showOnlyLinesWithPlanBudget: boolean): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, showOnlyLinesWithPlanBudget },
        };
    }

    public static setShowOnlyLinesWithNegativePlanFactDiff(
        state: PageState,
        showOnlyLinesWithNegativePlanFactDiff: boolean,
    ): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, showOnlyLinesWithNegativePlanFactDiff },
        };
    }

    public static setCorrectionsToDisplay(state: PageState, payload: LineCorrectionTypes): PageState {
        return {
            ...state,
            pageFilters: {
                ...state.pageFilters,
                correctionsToDisplay: payload,
            },
        };
    }

    public static setPreloaderStatus(state: PageState, payload: boolean): PageState {
        return { ...state, preloader: payload };
    }

    public static setCorrectionPopup(state: PageState, payload: CorrectionPopup): PageState {
        return {
            ...state,
            correctionPopup: {
                ...payload,
            },
        };
    }

    public static loadFilters(state: PageState, payload: LoadFiltersPaylaod): PageState {
        const { columnName, filters } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    [columnName]: filters,
                },
            },
        };
    }

    public static setFiltersLoadingStatus(state: PageState, payload: SetFiltersLoadingStatusPayload): PageState {
        const { columnName, loadingStatus } = payload;

        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                loadingStatus: {
                    ...state.columnFilters.loadingStatus,
                    [columnName]: loadingStatus,
                },
            },
        };
    }

    public static resetBudgetRelatedData(state: PageState): PageState {
        const {
            pageData: {
                activityBudgets,
                budgetItems,
                corrections,
                budgetItemsToIgnoreFilters,
                budgetSchema,
                budgetColumns,
            },
            columnFilters,
        } = Reducer.makeInitialState();

        const loadingStatus = budgetColumns.all.reduce((acc, column) => {
            acc[column.name] = LoadingStatus.NOT_LOADED;

            return acc;
        }, {} as PageState['columnFilters']['loadingStatus']);

        return {
            ...state,
            pageData: {
                ...state.pageData,
                activityBudgets,
                budgetItems,
                corrections,
                budgetItemsToIgnoreFilters,
                budgetColumns,
                budgetSchema,
            },
            columnFilters: {
                ...columnFilters,
                loadingStatus,
            },
        };
    }

    public static initPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static setPreviouslyLoadedFilter(state: PageState, payload: SetPreviouslyLoadedFilterPayload): PageState {
        const { columnName, filter } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                [columnName]: filter,
            },
        };
    }

    public static setPreviouslyLoadedFilters(state: PageState, payload: SetPreviouslyLoadedFiltersPayload): PageState {
        const { filters } = payload;

        return {
            ...state,
            previouslyLoadedFilters: {
                ...state.previouslyLoadedFilters,
                ...filters,
            },
        };
    }

    public static resetPreviouslyLoadedFilters(state: PageState): PageState {
        return {
            ...state,
            previouslyLoadedFilters: state.columnFilters.filters,
        };
    }

    public static setHoveredLineId(state: PageState, hoveredLineId: string): PageState {
        return { ...state, hoveredLineId };
    }

    public static setShowTagsHaveChangedMarker(state: PageState, showTagsHaveChangedMarker: boolean): PageState {
        return { ...state, showTagsHaveChangedMarker };
    }

    public static setMultiReferenceDictionaryApi(
        state: PageState,
        multiReferenceDictionaryApi: MultiReferenceDictionaryApi,
    ): PageState {
        return { ...state, multiReferenceDictionaryApi };
    }

    public static setTableLinesCellsParams(state: PageState, tableLinesCellsParams: TableLinesCellsParams): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, tableLinesCellsParams },
        };
    }

    public static setPageBudgetItems(state: PageState, pageBudgetItems: BudgetItem[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, pageBudgetItems },
        };
    }

    public static setFiltersState(state: PageState, filters: Filters): PageState {
        return {
            ...state,
            columnFilters: {
                ...state.columnFilters,
                filters: {
                    ...state.columnFilters.filters,
                    ...filters,
                },
            },
        };
    }

    public static setPageFilters(state: PageState, pageFilters: Partial<PageFilters>): PageState {
        return {
            ...state,
            pageFilters: { ...state.pageFilters, ...pageFilters },
        };
    }

    public static setLineIdsWithActualChanges(state: PageState, lineIdsWithActualChanges: string[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, lineIdsWithActualChanges },
        };
    }

    public static resetLoadedFilters(state: PageState): PageState {
        const {
            pageData: { budgetColumns },
        } = state;

        const loadingStatus = budgetColumns.all.reduce((acc, column) => {
            acc[column.name] = LoadingStatus.NOT_LOADED;

            return acc;
        }, {} as PageState['columnFilters']['loadingStatus']);

        return {
            ...state,
            columnFilters: { ...state.columnFilters, loadingStatus },
        };
    }

    public static setTableLines(state: PageState, tableLines: TableLine[]): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, tableLines },
        };
    }

    public static setDropdownOptions(state: PageState, dropdownOptions: DropdownOptions): PageState {
        return {
            ...state,
            computedData: { ...state.computedData, dropdownOptions },
        };
    }

    public static setPreventUserConfigUpdate(state: PageState, preventUserConfigUpdate: boolean): PageState {
        return { ...state, preventUserConfigUpdate };
    }

    public static setEmptyActivityBudgets(state: PageState, emptyActivityBudgets: ActivityBudget[]): PageState {
        return { ...state, computedData: { ...state.computedData, emptyActivityBudgets } };
    }

    public static setShowEmptyActivities(state: PageState, showEmptyActivities: boolean): PageState {
        return { ...state, pageFilters: { ...state.pageFilters, showEmptyActivities } };
    }
}

function validateNewChange(budgetColumns: BudgetColumns, newChange: UnsavedChange): UnsavedChange {
    const column = budgetColumns.byName[newChange.columnName];

    const updatedNewChange = lodash.clone(newChange);

    const isPreviousFundsCell = newChange.columnName == ColumnName.LastYearFact;

    if (isPreviousFundsCell && updatedNewChange.value) {
        const [intPart, fractionalPart] = (updatedNewChange.value as string).split('.');

        if (fractionalPart) {
            updatedNewChange.value = `${intPart}.${fractionalPart.slice(0, 2)}`;
        }
    }

    if (
        column.customCellType == CustomCellType.Input &&
        column.valueType == CellValueType.String &&
        lodash.get(column, 'metaData.maxLength') !== undefined &&
        updatedNewChange.value
    ) {
        updatedNewChange.value = (updatedNewChange.value as string).slice(0, column.metaData.maxLength);
    }

    return updatedNewChange;
}

export function applyChangesToChangeList(
    changeList: ChangeList,
    changes: UnsavedChange[],
    budgetColumns: BudgetColumns,
    generateSameValueChange?: boolean,
): ChangeList {
    changes.forEach((change) => {
        const changeColumn = budgetColumns.byName[change.columnName];
        const lineId = change.budgetItemId;

        const lineChanges = changeList[lineId] || [];

        const oldChange = lineChanges.find((item) => item.columnName == change.columnName);

        const newLineChanges = lodash.without(lineChanges, oldChange);

        let valueHaveChanged = change.value !== change.originalValue;
        if (changeColumn?.valueType === CellValueType.Currency) {
            const formulaValueBefore = change.originalValue
                ? Utils.calculateCurrencyFormula(change.originalValue as string)
                : 0;
            const formulaValueAfter = change.value ? Utils.calculateCurrencyFormula(change.value as string) : 0;

            valueHaveChanged = formulaValueAfter !== formulaValueBefore;
        }

        if (valueHaveChanged || generateSameValueChange) {
            newLineChanges.push(change);
        }

        changeList[lineId] = newLineChanges;
    });

    return changeList;
}

function removeChangesFromChangeList(changeList: ChangeList, changes: UnsavedChange[]): ChangeList {
    changes.forEach((item) => {
        changeList[item.budgetItemId] = lodash.without(changeList[item.budgetItemId], item);
    });

    return changeList;
}

function mergeCorrections<T extends CorrectionType>(
    currentCorrections: GroupedCorrections<T>,
    newCorrections: GroupedCorrections<T>,
): GroupedCorrections<T> {
    const updatedCorrections = lodash.clone(currentCorrections);

    lodash.forEach(newCorrections, (item, key) => {
        updatedCorrections[key] = item;
    });

    return updatedCorrections;
}

const budgetExecutionPageStateReducer = reducerWithInitialState(Reducer.makeInitialState())
    .case(actions.loadPageData, Reducer.loadPageData)
    .case(actions.updateLinesData, Reducer.updateLinesData)
    .case(actions.initUnsavedChanges, Reducer.initUnsavedChanges)
    .case(actions.resetPageStore, Reducer.makeInitialState)
    .case(actions.setColumnsVisiblityFilter, Reducer.setColumnsVisiblityFilter)
    .case(actions.setColumnsWidth, Reducer.setColumnsWidth)
    .case(actions.setResizingColumnName, Reducer.setResizingColumnName)
    .case(actions.setHoveredColumnName, Reducer.setHoveredColumnName)
    .case(actions.setSortingMode, Reducer.setSortingMode)
    .case(actions.setFilters, Reducer.setFilters)
    .case(actions.resetFilter, Reducer.resetFilter)
    .case(actions.resetFilters, Reducer.resetFilters)
    .case(actions.resetViewSettings, Reducer.resetViewSettings)
    .case(actions.setValidationStatus, Reducer.setValidationStatus)
    .case(actions.toggleColumnFix, Reducer.toggleColumnFix)
    .case(actions.setFixedColumnsNames, Reducer.setFixedColumnsNames)
    .case(actions.setActivityReferenceMenuVisibility, Reducer.setActivityReferenceMenuVisibility)
    .case(actions.setCreativeReferenceMenuVisibility, Reducer.setCreativeReferenceMenuVisibility)
    .case(actions.changeCellValueAsync.done, Reducer.changeCellValueDone)
    .case(actions.undoUnsavedChanges, Reducer.undoUnsavedChanges)
    .case(actions.redoUnsavedChanges, Reducer.redoUnsavedChanges)
    .case(actions.clearUnsavedChanges, Reducer.clearUnsavedChanges)
    .case(actions.clearUnsavedChangesByLines, Reducer.clearUnsavedChangesByLines)
    .case(actions.resetChangesHistory, Reducer.resetChangesHistory)
    .case(actions.setShowOnlyLinesWithoutPlan, Reducer.setShowOnlyLinesWithoutPlan)
    .case(actions.setShowOnlyLinesWithNegativeBalance, Reducer.setShowOnlyLinesWithNegativeBalance)
    .case(actions.setUseLinesWithoutPlanInSorting, Reducer.setUseLinesWithoutPlanInSorting)
    .case(actions.setShowOnlyLinesWithPlanBudget, Reducer.setShowOnlyLinesWithPlanBudget)
    .case(actions.setShowOnlyLinesWithNegativePlanFactDiff, Reducer.setShowOnlyLinesWithNegativePlanFactDiff)
    .case(actions.setCorrectionsToDisplay, Reducer.setCorrectionsToDisplay)
    .case(actions.setCorrectionPopup, Reducer.setCorrectionPopup)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.setPreloaderStatus, Reducer.setPreloaderStatus)
    .case(actions.loadFilters, Reducer.loadFilters)
    .case(actions.loadBudgetItems, Reducer.loadBudgetItems)
    .case(actions.loadActivityBudgets, Reducer.loadActivityBudgets)
    .case(actions.setFiltersLoadingStatus, Reducer.setFiltersLoadingStatus)
    .case(actions.resetBudgetRelatedData, Reducer.resetBudgetRelatedData)
    .case(actions.initPreviouslyLoadedFilter, Reducer.initPreviouslyLoadedFilters)
    .case(actions.setPreviouslyLoadedFilter, Reducer.setPreviouslyLoadedFilter)
    .case(actions.setPreviouslyLoadedFilters, Reducer.setPreviouslyLoadedFilters)
    .case(actions.resetPreviouslyLoadedFilters, Reducer.resetPreviouslyLoadedFilters)
    .case(actions.setAppliedFiltersNames, Reducer.setAppliedFiltersNames)
    .case(actions.setHoveredLineId, Reducer.setHoveredLineId)
    .case(actions.setShowTagsHaveChangedMarker, Reducer.setShowTagsHaveChangedMarker)
    .case(actions.setPageBudgetItems, Reducer.setPageBudgetItems)
    .case(actions.setMultiReferenceDictionaryApi, Reducer.setMultiReferenceDictionaryApi)
    .case(actions.setTableLinesCellsParams, Reducer.setTableLinesCellsParams)
    .case(actions.setFiltersState, Reducer.setFiltersState)
    .case(actions.setPageFilters, Reducer.setPageFilters)
    .case(actions.setLineIdsWithActualChanges, Reducer.setLineIdsWithActualChanges)
    .case(actions.resetLoadedFilters, Reducer.resetLoadedFilters)
    .case(actions.setTableLines, Reducer.setTableLines)
    .case(actions.setDropdownOptions, Reducer.setDropdownOptions)
    .case(actions.setPreventUserConfigUpdate, Reducer.setPreventUserConfigUpdate)
    .case(actions.setEmptyActivityBudgets, Reducer.setEmptyActivityBudgets)
    .case(actions.setShowEmptyActivities, Reducer.setShowEmptyActivities);

export const budgetExecutionPageReducer = combineReducers<BudgetExecutionPageState>({
    budgetTransferMenuState: budgetTransferMenuReducer,
    lineModal: lineModalReducer,
    transferBudgetItemsToPlannningMenu: transferBudgetItemsToPlanningMenuReducer,
    miscBudgetItems: miscBudgetItemsReducer,
    activityReferenceMenu: activityReferenceMenuReducer,
    creativeReferenceMenu: creativeReferenceMenuReducer,
    importFMPTableMenu: importFMPTableMenuReducer,
    restState: budgetExecutionPageStateReducer,
});
