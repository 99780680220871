import { axios } from '../lib/axios';

const BASE_PATH = '/api/system';

export class SystemApi {
    public static async getLocales(): Promise<object> {
        const res = await axios.get<object>(`${BASE_PATH}/locales`);

        return res.data;
    }
}
