import { Success } from 'typescript-fsa';

import { TransferPlanFundsFormState, ParticipatorDataState as State } from '../types';

export class ParticipatorDataReducer {
    public static makeInitialState(): State {
        return { participatorItemId: null, participatorComment: null };
    }

    public static mergeAfterAsyncDone(
        state: TransferPlanFundsFormState,
        payload: Success<any, Partial<State>>,
    ): TransferPlanFundsFormState {
        return ParticipatorDataReducer.merge(state, payload.result);
    }

    public static merge(
        state: TransferPlanFundsFormState,
        participatorData: Partial<State>,
    ): TransferPlanFundsFormState {
        return { ...state, participatorData: { ...state.participatorData, ...participatorData } };
    }
}
