import { bindThunkAction, AsyncWorker } from 'typescript-fsa-redux-thunk';
import { ChangeableBudgetItemStatus, BudgetItemStatus } from '@mrm/budget';

import { StoreState } from '@store';

import { BudgetItemApi } from '@api';

import * as actions from '../actions/sync';
import * as asyncActions from '../actions/async';

export const sendBudgetItemToExpertApprovement = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.sendBudgetItemToExpertApprovement,
    statusChangeThunk(BudgetItemStatus.OnExpertApprovement),
);

export const deleteBudgetItem = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.deleteBudgetItem,
    statusChangeThunk(BudgetItemStatus.Disabled),
);

export const publishBudgteItem = bindThunkAction<StoreState, string, void, Error>(
    asyncActions.publishBudgteItem,
    statusChangeThunk(BudgetItemStatus.Published),
);

function statusChangeThunk(status: ChangeableBudgetItemStatus): AsyncWorker<void, string, StoreState> {
    return async function changeStatusThunk(budgetItemId, dispatch, getState) {
        await BudgetItemApi.changeBudgetItemStatus({
            id: budgetItemId,
            status,
        });

        const budgetItem = await BudgetItemApi.getBudgetItem(budgetItemId);
        dispatch(actions.syncDataAfterCorrection({ activityBudgets: [], budgetItems: [budgetItem] }));
    };
}
